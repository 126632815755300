import { UserState } from "redux/user/user_types";
import cls from "./style/UserName.module.scss";
import { useTypedSelector } from "hooks/useTypedSelector";
import { Link } from "react-router-dom";

const UserName = () => {
  const user: UserState = useTypedSelector((state) => state.user);
  return (
    <div className={cls.ctnUserName}>
      <Link to="/profile" className={cls.userName}>
        {user.data.email}
      </Link>
    </div>
  );
};

export default UserName;
