import React, { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import ModalInput from "../ui/Inputs/ModalInput";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import ModalWindow from "./ModalWindow";
import toast from "react-hot-toast";
import Checkbox from "components/ui/Checkbox/Checkbox";
import CustomDatePicker from "components/datepicker/CustomDatePicker";
import moment from "moment";
import { CarState } from "redux/car/car_types";
import { ConstructorState } from "redux/constructor/constructor_types";
import TyreEdit from "components/constructor/placementTyre/TyreEdit";
import api from "api";
import cls from "./style/StyleAddTyre.module.scss";
import notTC from "../../assets/images/notCar.svg";

const ModalAddTyre = () => {
  const [model, setModel] = useState<string>("");
  const [brand, setBrand] = useState<string>("");
  const [size, setSize] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [tread_start, setTreadStart] = useState<string>("");
  const [tread, setTread] = useState<string>("");
  const [dot, setDot] = useState<string>("");
  const [production_date, setProdDate] = useState(moment().format("YYYY-MM-DD"));
  const [run_start, setRunStart] = useState<string>("");
  const [place, setPlace] = useState<number>(1);
  const [used, setUsed] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({
    title: [],
    region: [],
    car_number: [],
    comment: [],
    code: [],
  });
  const [position_tyre, setPositionTyre] = useState<number[]>([]);
  const { toggleModal, addTyre, closeActionModal, carTyrePosition } = useActions();
  const [submiting, setSubmiting] = useState<boolean>(false);
  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const { car }: CarState = useTypedSelector((state) => state.car);
  const constructor: ConstructorState = useTypedSelector((state) => state.design);
  const { modelId, trailerId } = constructor;
  const { position_tyres } = useTypedSelector<any>((state) => state.car);
  const isEmpty = position_tyre.length === 0;
  const filtered_position = position_tyres?.filter((el: any) => el.carId === car?.id);
  const data_positions = filtered_position[0]?.positions;
  const noErrors = Object.values(errors).every((errorsArr: any) => errorsArr.length === 0);

  const isCompleted = (data: any) => {
    return Object.values(data).every((value) => value !== "");
  };
  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_add_tyre" });
    setModel("");
    setBrand("");
    setSize("");
    setPrice("");
    setTreadStart("");
    setTread("");
    setDot("");
    setProdDate(moment().format("YYYY-MM-DD"));
    setPlace(1);
    setRunStart("");
    setUsed(false);
    setErrors({
      title: [],
      region: [],
      car_number: [],
      code: [],
    });
    setPositionTyre([]);
  };
  const onDateChoose = (from: string) => {
    setProdDate(from);
  };

  const handlePositions = (positions: number[]) => {
    setPositionTyre(positions);
  };

  const pos = position_tyres[0]?.positions;
  const slicePositions = pos?.slice(0, -1);

  const handleSave = async (e: React.FormEvent) => {
    const data = {
      brand,
      model,
      dot,
      size,
      production_date,
      price,
      tread_start,
      used,
      position_tyre,
      place,
      run_start: run_start !== "" ? run_start : 0,
      vehicle_id: car ? car.id : 0,
      type: "adding",
      run: run_start,
      tread: tread ? tread : tread_start,
    };
    if (isEmpty) {
      e.preventDefault();

      toast.error("Выберите позиции шины");
    } else {
      e.preventDefault();
      e.stopPropagation();
      try {
        if (car?.id !== undefined && noErrors) {
          e.preventDefault();
          console.log("Данные готовы к отправке:", !isCompleted(data));
          console.log("Отправка на бэк произошла");
          await api.putCar(car?.id, {
            position_tyres: data_positions,
          });

          // carTyrePosition(car?.id, data_positions);
        } else {
          console.log("Отправка на бэк не произошла");
        }
        setSubmiting(true);
        await addTyre(data);
        console.log(data);

        closeActionModal(true);
        toast.success("Вы успешно добавили шину");
        handleClose();
      } catch (error: any) {
        if (error?.response?.data?.errors) {
          if (car?.id) {
            carTyrePosition(car?.id, slicePositions);
          }
          setErrors({
            ...errors,
            brand: error?.response?.data?.errors?.brand || [],
            model: error?.response?.data?.errors?.model || [],
            dot: error?.response?.data?.errors?.dot || [],
            size: error?.response?.data?.errors?.size || [],
            production_date: error?.response?.data?.errors?.production_date || [],
            price: error?.response?.data?.errors?.price || [],
            tread_start: error?.response?.data?.errors?.tread_start || [],
            place: error?.response?.data?.errors?.place || [],
            used: error?.response?.data?.errors?.used || [],
            run_start: error?.response?.data?.errors?.run_start || [],
          });
        } else if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
      setSubmiting(false);
    }
  };
  if (submiting) {
    return (
      <ModalWindow
        isShow={modals.modal_add_tyre}
        title="Добавление шины"
        handleClose={handleClose}
        dialogClassName="modal__box-big"
      >
        <SkeletonLoading height="382px" />
      </ModalWindow>
    );
  }

  // const handleChangeError = (e: React.FormEvent<HTMLInputElement>, type: "run" | "tread") => {
  //   const value: any = +(e.target as HTMLInputElement).value;
  //   const
  //   setErrors((prevError: any) => ({
  //     ...prevError,
  //     [type]: [],
  //   }));

  //   if (type === "run") {
  //     setRun(value);
  //     if (lastAction && lastAction?.run > value) {
  //       setErrors((prevError: any) => ({
  //         ...prevError,
  //         run: "Пробег не может быть меньше предыдущего",
  //       }));
  //     }
  //   } else if (type === "tread") {
  //     setTread(value);
  //     if (lastAction && lastAction?.tread < value) {
  //       setErrors((prevError: any) => ({
  //         ...prevError,
  //         tread: "Глубина не может быть больше предыдущего",
  //       }));
  //     }
  //   }
  // };

  return (
    <ModalWindow
      isShow={modals.modal_add_tyre}
      title="Добавление шины"
      handleClose={handleClose}
      dialogClassName="modal__box-big"
    >
      <form id="addTransport" onSubmit={handleSave} className="modal-content__box">
        <div>
          <p className="model-title__bold">Местоположение шины</p>
          {modelId && car ? (
            <>
              <TyreEdit
                carId={car?.id}
                modelId={modelId}
                defaultPositions={data_positions || car?.position_tyres}
                trailerId={trailerId}
                wheelCount={8}
                handlePositions={handlePositions}
                tyres={data_positions || car?.position_tyres} /// пробовать вытаскивать из шины
              />
            </>
          ) : (
            <div className={cls.cntNoConstructor}>
              <div className={cls.ctnImg}>
                <img className={cls.img} src={notTC} alt="TC" />
              </div>
              <div className={cls.content}>Для данного транспорта конструктор не создан.</div>
            </div>
          )}
        </div>
        <div>
          <p className="model-title__bold">Модель и размеры шин</p>
          <div style={{ marginBottom: "5px" }}>
            <Checkbox
              onChange={() => setUsed((used) => !used)}
              checked={used}
              text="Б/У"
              titleClasses="modal-password_title"
            />
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Бренд"
              // length={7}
              value={brand}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setBrand((e.target as HTMLInputElement).value)
              }
              errors={errors.brand}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Модель"
              // length={3}
              value={model}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setModel((e.target as HTMLInputElement).value)
              }
              errors={errors.model}
            />
          </div>
          <ModalInput
            titleClass="modal-password_title"
            classes={["modal-title__half"]}
            title="Размер"
            // length={3}
            value={size}
            onChange={(e: any) => setSize(e.target.value)}
            errors={errors.size}
          />
        </div>
        <div>
          <p className="model-title__bold">Характеристики производителя</p>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="DOT"
              type="number"
              length={12}
              value={dot}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setDot((e.target as HTMLInputElement).value)
              }
              errors={errors.dot}
            />
            <div className="input-area input-area--border w100 m0">
              <label className="label">Дата производства</label>
              <CustomDatePicker
                onChange={onDateChoose}
                date={production_date}
                classes="react-datepicker_big"
              />
              {errors.production_date && typeof errors.production_date === "string" && (
                <div className="input-error-message">{errors.production_date}</div>
              )}
            </div>
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Глубина протектора"
              type="number"
              // length={32}
              value={tread_start}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setTreadStart((e.target as HTMLInputElement).value)
              }
              errors={errors.tread_start}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Стоимость"
              value={price}
              type="number"
              // length={16}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setPrice((e.target as HTMLInputElement).value)
              }
              errors={errors.price}
            />
          </div>
        </div>
        {used && (
          <div>
            <p className="model-title__bold">Начальные параметры для б/у шин</p>
            <div className="modal__inputs-box">
              <ModalInput
                titleClass="modal-password_title"
                title="Пробег"
                type="number"
                // length={32}
                value={run_start}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setRunStart((e.target as HTMLInputElement).value)
                }
                errors={errors.run_start}
              />
              <ModalInput
                titleClass="modal-password_title"
                title="Глубина протектора"
                value={tread}
                type="number"
                // length={16}
                onChange={(e: any) => setTread(e.target.value)}
                errors={errors.tread}
              />
            </div>
          </div>
        )}
      </form>
      <div className="modal-edit__button-box">
        <button form="addTransport" type="submit" className="modal__button">
          Сохранить
        </button>
      </div>
    </ModalWindow>
  );
};

export default ModalAddTyre;
