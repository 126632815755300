import { TyreProps } from "redux/tyres/tyres_types";

export interface ActionsState {
  actions: ActionPeriodProps | null;
  filter: { [key: string]: string } | null;
  loading: boolean;
  period: ActionPeriodProps | null;
  visible: boolean;
  type: string;
  reset: boolean;
  countRun: number;
  type_input: string;
  select_value_util: string;
  select_type_journal: string;
  closeAction: boolean;
  detail_repair: [] | null;
  axel_action_all: [] | null;
}

export interface AddingProps {
  id: number;
  action_id: number;
  vehicle_id: number;
  updated_at: string;
  creates_at: string;
}

export interface MovingProps {
  id: number;
  action_id: number;
  from: number;
  to: number;
  updated_at: string;
  creates_at: string;
}

export interface ActionProps {
  id: number;
  tyre_id: number;
  type: string;
  run: number;
  tread: number;
  updated_at: string;
  creates_at: string;
  tyre: TyreProps;
  utilization?: UtilizationActionProps;
  store?: StoreActionProps;
  moving?: MovingProps;
  adding?: AddingProps;
}

export interface ActionPeriodProps {
  actions: ActionProps[];
  from?: string;
  to?: string;
  count?: number;
}

export interface UtilizationActionProps {
  id: number;
  action_id: number;
  reason: string;
  comment: string;
  updated_at: string;
  creates_at: string;
}

export interface StoreActionProps {
  id: number;
  action_id: number;
  vehicle_id: string;
  reason: string;
  updated_at: string;
  creates_at: string;
}

export enum ActionActionTypes {
  GET_ACTIONS = "GET_ACTIONS",
  ACTION_LOADING = "ACTION_LOADING",
  ACTIONS_FILTER = "ACTIONS_FILTER",
  SET_SHOW = "SET_SHOW",
  CHOOSING_PERIOD = "CHOOSING_PERIOD",
  VISIBLE_ACTIONS = "VISIBLE_ACTIONS",
  TYPE_OF_EVENT_ACTIONS = "TYPE_OF_EVENT_ACTIONS",
  RESET_ACTION = "RESET_ACTION",
  INPUT_TYPE = "INPUT_TYPE",
  SELECT_TYPE_UTIL = "SELECT_TYPE_UTIL",
  SELECT_TYPE_JOURNAL_EVENT = "SELECT_TYPE_JOURNAL_EVENT",
  DELETE_ACTION = "DELETE_ACTION",
  CLOSE = "CLOSE",
  CLOSE_RESET = "CLOSE_RESET",
  DETAIL_TYRE_REPAIR = "DETAIL_TYRE_REPAIR",
  GET_AXEL_ACTION_ALL = "GET_AXEL_ACTION_ALL",
}

interface GetActions {
  type: ActionActionTypes.GET_ACTIONS;
  payload: ActionPeriodProps;
}

interface ActionLoading {
  type: ActionActionTypes.ACTION_LOADING;
  payload: boolean;
}

interface ActionShow {
  type: ActionActionTypes.SET_SHOW;
  payload: boolean;
}

interface ActionFilter {
  type: ActionActionTypes.ACTIONS_FILTER;
  payload: { [key: string]: string };
}

interface ActionFilter {
  type: ActionActionTypes.ACTIONS_FILTER;
  payload: { [key: string]: string };
}

interface PeriodTyreActions {
  type: ActionActionTypes.CHOOSING_PERIOD;
  payload: [];
}
interface VisibleActions {
  type: ActionActionTypes.VISIBLE_ACTIONS;
  payload: boolean;
}
interface TypeOfEventActions {
  type: ActionActionTypes.TYPE_OF_EVENT_ACTIONS;
  payload: string;
}

interface ResetAction {
  type: ActionActionTypes.RESET_ACTION;
  payload: boolean;
}

interface InputTypeSearch {
  type: ActionActionTypes.INPUT_TYPE;
  payload: string;
}

interface SelectTypeUtil {
  type: ActionActionTypes.SELECT_TYPE_UTIL;
  payload: string;
}

interface SelectTypeJournal {
  type: ActionActionTypes.SELECT_TYPE_JOURNAL_EVENT;
  payload: string;
}

interface Close {
  type: ActionActionTypes.CLOSE;
  payload: boolean;
}

interface CloseReset {
  type: ActionActionTypes.CLOSE_RESET;
  payload: boolean;
}

interface DetailTyreRepair {
  type: ActionActionTypes.DETAIL_TYRE_REPAIR;
  payload: [];
}

interface GetAxelChangeActionAll {
  type: ActionActionTypes.GET_AXEL_ACTION_ALL;
  payload: [];
}

export type ActionsActions =
  | GetAxelChangeActionAll
  | SelectTypeJournal
  | SelectTypeUtil
  | InputTypeSearch
  | GetActions
  | ActionLoading
  | ActionFilter
  | ActionShow
  | DetailTyreRepair
  | Close
  | CloseReset
  | PeriodTyreActions
  | VisibleActions
  | ResetAction
  | TypeOfEventActions;
