import cls from "./style/SupportContact.module.scss";

const SupportContact = () => {
  return (
    <>
      <div className={cls.ctnSupport}>
        <a href="tel:+84957276700" className={`${cls.text} ${cls.active}`}>
          8 (495) <strong>727-67-00</strong>
        </a>
        <div className={cls.text}>- ПН-ПТ С 08:00 ДО 17:00</div>
        <div className={cls.line}></div>
        <a href="mailto:nk@tyrecontrol.ru" className={`${cls.text} ${cls.active}`}>
          <strong>nk</strong>@tyrecontrol.ru
        </a>
      </div>
    </>
  );
};

export default SupportContact;
