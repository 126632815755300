import { models } from "../../../helpers/constructorModels";
import { useState, useEffect } from "react";
import { trailers } from "helpers/constructorTrailers";
import { useTypedSelector } from "hooks/useTypedSelector";
import { CarState } from "redux/car/car_types";
import { useActions } from "hooks/useAction";

const TyreEdit = ({
  carId,
  modelId,
  trailerId,
  handlePositions,
  defaultPositions,
  tyres,
}: {
  carId: number;
  modelId: number;
  trailerId: null | number;
  wheelCount: number;
  handlePositions: (positions: number[]) => void;
  defaultPositions: any;
  tyres: [] | [[number, number]];
}) => {
  const processTyres = (positions: any) => {
    if (Array.isArray(positions)) {
      return positions;
    } else if (typeof positions === "string") {
      return positions
        .replace(/^\[|\]$/g, "")
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item !== "null" && item !== "")
        .map(Number);
    } else {
      console.error("Invalid input: expected a string or an array");
      return [];
    }
  };
  const arrayTyres = processTyres(defaultPositions);
  const { carTyrePosition } = useActions();
  const [tyrePositions, setTyrePositions] = useState<number[]>(arrayTyres);
  const [newPositions, setNewPositions] = useState<number[]>([]);
  const { closeAction } = useTypedSelector((state) => state.actions);

  const modelAxes = [...models[modelId].axes].reverse();
  const trailerAxes = trailerId !== null ? [...trailers[trailerId].axes].reverse() : [];
  let isEditable = tyrePositions.length !== tyres?.length;
  if (tyres.length >= 0) {
    isEditable = true;
  }
  useEffect(() => {
    handlePositions(newPositions);
  }, [newPositions]);

  const getWheelByIndex = (index: number) => {
    if (tyrePositions.indexOf(index) === -1) return null;
    return tyrePositions.indexOf(index) + 1;
  };
  const handleClick = async (index: number) => {
    if (!isEditable) return;
    const newSP = [...tyrePositions];
    newSP.push(index);
    setNewPositions([index]);
    setTyrePositions(newSP);
    carTyrePosition(carId, newSP);
  };
  const reset = () => {
    setTyrePositions([]);
  };
  let wheelIndex = 0;

  const axesListModel = modelAxes.map((axis, axisIndex) => {
    const wheels = axis.wheels.map((offset) => {
      wheelIndex++;
      const wIndex = wheelIndex;
      const content = getWheelByIndex(wIndex);
      const classes = ["wheel"];
      if (axis.mini) classes.push("wheel--mini");
      if (isEditable && !content) classes.push("wheel--editable");
      classes.push(!content ? "wheel--empty" : "wheel--filled_tyres_position");

      return (
        <div
          className="wheel-container"
          style={{ transform: `translate(0, ${offset}px)` }}
          key={wIndex}
          onClick={() => (!content ? handleClick(wIndex) : {})}
        >
          <div className={classes.join(" ")}>
            {/* [{wIndex}] */}
            {/* {!!content && content} */}
            {/* {!content && <span className="hidden-index">{tyrePositions.length + 1}</span>} */}
          </div>
        </div>
      );
    });

    return (
      <div className="axis" key={axisIndex} style={{ transform: `translate(${axis.offset}px, 0)` }}>
        {wheels}
      </div>
    );
  });

  const axesListTrailer = trailerAxes.map((axis, axisIndex) => {
    const wheels = axis.wheels.map((offset) => {
      wheelIndex++;
      const wIndex = wheelIndex;
      const content = getWheelByIndex(wIndex);

      const classes = ["wheel"];
      if (axis.mini) classes.push("wheel--mini");
      if (isEditable && !content) classes.push("wheel--editable");
      classes.push(!content ? "wheel--empty" : "wheel--filled");
      classes.push(!content ? "wheel--empty" : "wheel--filled_tyres_position");

      return (
        <div
          className="wheel-container"
          style={{ transform: `translate(0, ${offset}px)` }}
          key={wIndex}
          onClick={() => (!content ? handleClick(wIndex) : {})}
        >
          <div className={classes.join(" ")}>
            {/* {!!content && content} */}
            {!content && <span className="hidden-index">{tyrePositions.length + 1}</span>}
          </div>
        </div>
      );
    });

    return (
      <div className="axis" key={axisIndex} style={{ transform: `translate(${axis.offset}px, 0)` }}>
        {wheels}
      </div>
    );
  });

  return (
    <div>
      <div className="view-model__container">
        <div
          className="view-model"
          style={{
            padding: `${models[modelId].globalPadding}px 0`,
          }}
        >
          {models[modelId].flat}
          {newPositions.length > 0 ? (
            <div className="wheels" style={{ pointerEvents: "none" }}>
              {axesListModel}
            </div>
          ) : (
            <div className="wheels">{axesListModel}</div>
          )}
        </div>

        {trailerId && (
          <div
            className="view-model view-model--trailer"
            style={{
              padding: `${trailers[trailerId].globalPadding}px 0`,
            }}
          >
            {trailers[trailerId].flat}
            {newPositions.length > 0 ? (
              <div className="wheel" style={{ position: "absolute", pointerEvents: "none" }}>
                {axesListTrailer}
              </div>
            ) : (
              <div className="wheel" style={{ position: "absolute" }}>
                {axesListTrailer}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TyreEdit;
