import {
  Vehicle,
  VehicleFullNumber,
  VehicleInfo,
  VehicleNumber,
  VehicleRegion,
  VehicleWrapper,
} from "components/cars/Cars.styled";
interface TransportInfoProps {
  car_number: number;
  region: number;
  id: number;
  title: string;
}

const TransportInfo: React.FC<TransportInfoProps> = ({ car_number, region, id, title }) => {
  return (
    <VehicleWrapper>
      <Vehicle>
        <VehicleInfo>
          <VehicleFullNumber>
            <VehicleNumber>{car_number}</VehicleNumber>
            <VehicleRegion>{region}</VehicleRegion>
          </VehicleFullNumber>
          <div key={id} className="vehicle__name">
            {title}
          </div>
        </VehicleInfo>
      </Vehicle>
    </VehicleWrapper>
  );
};

export default TransportInfo;
