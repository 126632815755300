import { Dispatch } from "redux";
import { TyresActionTypes, TyresActions } from "./tyres_types";
import api from "api";

export const getTyres = () => {
  return async (dispatch: Dispatch<TyresActions>) => {
    try {
      dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: true });

      const response = await api.getTyres();
      dispatch({
        type: TyresActionTypes.GET_TYRES,
        payload: response.tyres,
      });
      dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: false });
    } catch (e: any) {
      return e;
    }
  };
};

export const getCarTyres = (id: number) => {
  return async (dispatch: Dispatch<TyresActions>) => {
    try {
      dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: true });
      const response = await api.getCarTyres(id);
      dispatch({
        type: TyresActionTypes.GET_CAR_TYRES,
        payload: response.tyres,
      });
      dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: false });
    } catch (e: any) {
      return e;
    }
  };
};

export const addTyre = (data: any) => async (dispatch: Dispatch<TyresActions | any>) => {
  await api.addTyre(data);
  dispatch(getCarTyres(data.vehicle_id));
};

export const addActionAdding = (data: any) => async () => {
  await api.addActionAdding(data);
};

export const updateTyre =
  (id: number, data: any) => async (dispatch: Dispatch<TyresActions | any>) => {
    await api.putTyre(id, data);
  };

export const getCarUtilization = () => {
  return async (dispatch: Dispatch<TyresActions>) => {
    try {
      dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: true });

      const response = await api.getTyresDeleted();
      dispatch({
        type: TyresActionTypes.GET_TYRES,
        payload: response.tyres,
      });
      dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: false });
    } catch (e: any) {
      return e;
    }
  };
};

export const getCarStore = () => {
  return async (dispatch: Dispatch<TyresActions>) => {
    try {
      dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: true });

      const response = await api.getTyresStored();
      dispatch({
        type: TyresActionTypes.GET_TYRES,
        payload: response.tyres,
      });
      dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: false });
    } catch (e: any) {
      return e;
    }
  };
};

export const resetTyres = () => {
  return async (dispatch: Dispatch<TyresActions>) => {
    dispatch({
      type: TyresActionTypes.TYRES_RESET,
    });
  };
};

export const getTyre = (id: number) => {
  return async (dispatch: Dispatch<TyresActions>) => {
    try {
      dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: true });

      const response = await api.getTyre(id);
      dispatch({
        type: TyresActionTypes.GET_TYRE,
        payload: response.tyre,
      });

      dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: false });
    } catch (e: any) {
      return e;
    }
  };
};

export const postTyresByPeriod = (
  from: string,
  to: string,
  type: string | undefined = undefined
) => {
  return async (dispatch: Dispatch<TyresActions>) => {
    dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: true });
    const response = await api.postTyresByPeriod(from, to, type);
    dispatch({
      type: TyresActionTypes.GET_TYRES_BY_PERIOD,
      payload: response,
    });
    dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: false });
  };
};

export const updateValue = (value: any) => {
  return {
    type: TyresActionTypes.TYRE_EDIT,
    payload: value,
  };
};

export const getSklatTyre = (value: any) => {
  return {
    type: TyresActionTypes.GET_SKLAD_TYRE,
    payload: [...value],
  };
};

export const getIdTyreMovingCar = (id: any) => {
  return {
    type: TyresActionTypes.GET_ID_TYRE_MOVING,
    payload: id,
  };
};

export const postHistoryTyre = (data: any) => {
  try {
    return async (dispatch: Dispatch<TyresActions>) => {
      dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: true });
      const response: any = await api.postHistoryTyre(data);
      dispatch({
        type: TyresActionTypes.POST_HISTORY_TYRE,
        payload: response,
      });
      dispatch({ type: TyresActionTypes.TYRES_LOADING, payload: false });
    };
  } catch (error) {
    console.error(error);
  }
};
