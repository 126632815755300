import React from "react";
import cls from "./style/StyleTitleJournal.module.scss";

export default function TitleJournal() {
  return (
    <div className={cls.ctnTitle}>
      <div className={`${cls.text} ${cls.data}`}>ДАТА</div>
      <div className={cls.text}>СОБЫТИЕ</div>
      <div className={cls.text}>ТРАНСПОРТ</div>
      <div className={cls.text}>МЕСТОПОЛОЖЕНИЕ</div>
      <div className={`${cls.text} ${cls.run}`}>ПРОБЕГ</div>
      <div className={`${cls.text} ${cls.tread}`}>ГЛУБИНА ПРОТЕКТОРА</div>
    </div>
  );
}
