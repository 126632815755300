import { Map } from "yandex-maps";
import { IPoint, MapState } from "../../redux/map/map_types";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useEffect, useState, useRef } from "react";
import { makePoint } from "./mapHelper";
import { CarState } from "redux/car/car_types";
import { renderToString } from "react-dom/server";
import Balloon from "./Balloon";
import { getDistanceBetween, ICoord } from "./mapTypes";
import { useLocation } from "react-router-dom";

interface IPointWithMark extends IPoint {
  moved?: boolean;
}

const HandlePoint = ({ myMap }: { myMap: Map }) => {
  const map: MapState = useTypedSelector((state) => state.map);

  const { car }: CarState = useTypedSelector((state) => state.car);
  const [point, setPoint] = useState<IPointWithMark | null>();

  const [myPlacemark, setMyPlacemark] = useState<any>(null); // последнее расположение ТС

  // таймер на анимацию движения точки
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const location = useLocation();

  const clearTimer = () => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  };

  const isNotView = (carId: number): boolean => {
    const paths = location.pathname.split("/");
    return paths.length > 0 && paths[1] === "view" && paths[paths.length - 1] !== carId.toString();
  };

  useEffect(() => {
    if (!car) return;

    if (isNotView(car.id)) clearTimer();
  }, [location.pathname]);

  useEffect(() => {
    if (car !== null || !myMap) return;

    reset();
  }, [car]);

  const reset = () => {
    if (myPlacemark !== null) myMap.geoObjects.remove(myPlacemark);
    setMyPlacemark(null);
    setPoint(null);

    clearTimer();
  };

  useEffect(() => {
    if (!car || !map.point || isNotView(car.id)) {
      reset();
      return;
    }

    if (!!map.point && !!point) {
      if (
        map.point.id !== point.id &&
        map.point.device_id === point.device_id &&
        timer.current === null
      ) {
        // если пришла новая точка на ту же машину, то двигаем

        movePoint(point, map.point);
        return;
      } else if (map.point.id === point.id) return;
    }
    setPoint(map.point);
  }, [map.point]);

  useEffect(() => {
    // рисуем точку
    if (point && myMap && car) {
      if (!point.moved) {
        // просто рисуем
        const placemark = makePoint(point, car);

        if (myPlacemark !== null) {
          myMap.geoObjects.remove(myPlacemark);
        } else {
          myMap.panTo([point.lat, point.long], {
            flying: true,
            duration: 1000,
          });
        }

        myMap.geoObjects.add(placemark);
        setMyPlacemark(placemark);
      } else if (myPlacemark !== null) {
        // точка после анимации, переделываем балун

        myPlacemark.properties.set(
          "balloonContent",
          renderToString(
            <div className="map__balloon-content" id={`balloon-${point.id}`}>
              <Balloon point={point} car={car} />
            </div>
          )
        );
      }
    }

    if (myMap && !point && myPlacemark !== null) {
      myMap.geoObjects.remove(myPlacemark);
      setMyPlacemark(null);
    }
  }, [point]);

  const movePoint = (from: IPoint, to: IPoint) => {
    const distance = getDistanceBetween({ x: from.lat, y: from.long }, { x: to.lat, y: to.long });

    let max = 100;
    let i = 1;

    if (distance < 100) {
      max = 50;
    }

    const startLat = from.lat;
    const startLon = from.long;

    clearTimer();

    const deltaLat = (to.lat - startLat) / max;
    const deltaLon = (to.long - startLon) / max;

    function timerDraw({ x, y }: ICoord) {
      const newLat = x + deltaLat;
      const newLon = y + deltaLon;

      if (i < max) {
        myPlacemark.geometry.setCoordinates([+newLat, +newLon]);
        i++;

        timer.current = setTimeout(timerDraw, 140, {
          x: newLat,
          y: newLon,
        });
      } else {
        // console.log('=== ЗАКОНЧИЛИ ===');
        if (timer.current !== null) {
          if (map.point) {
            const movedPoint: IPointWithMark = map.point;
            movedPoint.moved = true;

            setPoint(movedPoint);
          }

          clearTimer();
        }
      }
    }

    if (deltaLat !== 0 && deltaLon !== 0) {
      timer.current = setTimeout(timerDraw, 140, {
        x: startLat,
        y: startLon,
      });

      // console.log("=== НАЧИНАЕМ ДВИГАТЬ ===");
    }
  };

  return <></>;
};

export default HandlePoint;
