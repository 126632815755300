import { TyresActionTypes, TyresActions, TyresState } from "./tyres_types";

const initialState: TyresState = {
  tyres: null,
  loading_tyres: true,
  tyres_by_car: null,
  tyre_edit: null,
  last_tyre: null,
  tyre: null,
  idTyreMovingCar: null,
  tyres_by_period: null,
};

const TyresReducer = (state = { ...initialState }, action: TyresActions) => {
  switch (action.type) {
    case TyresActionTypes.GET_TYRES:
      return {
        ...state,
        tyres: action.payload,
        loading_tyres: false,
      };
    case TyresActionTypes.GET_CAR_TYRES:
      return {
        ...state,
        tyres_by_car: action.payload,
        loading_tyres: false,
      };
    case TyresActionTypes.TYRES_LOADING:
      return {
        ...state,
        loading_tyres: action.payload,
      };
    case TyresActionTypes.TYRES_RESET:
      return {
        ...initialState,
      };
    case TyresActionTypes.GET_TYRE:
      return {
        ...state,
        tyre: action.payload,
        loading_tyres: false,
      };

    case TyresActionTypes.GET_TYRES_BY_PERIOD:
      return {
        ...state,
        tyres_by_period: action.payload,
        loading_tyres: false,
      };
    case TyresActionTypes.TYRE_EDIT:
      return {
        ...state,
        tyre_edit: action.payload,
      };
    case TyresActionTypes.GET_SKLAD_TYRE:
      return {
        ...state,
        skladTyre: action.payload,
      };
    case TyresActionTypes.GET_ID_TYRE_MOVING:
      return {
        ...state,
        id_tyre_moving: action.payload,
      };
    case TyresActionTypes.UPDATE_TYRE:
      return {
        ...state,
        update_data: action.payload,
      };
    case TyresActionTypes.POST_HISTORY_TYRE:
      return {
        ...state,
        historyTyre: action.payload,
      };

    default:
      return state;
  }
};

export default TyresReducer;
