import { ModalsState } from "redux/modals/modals_types";
import ModalWindow from "./ModalWindow";
import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useAction";
import { useEffect, useRef, useState } from "react";
import CustomDatePicker from "components/datepicker/CustomDatePicker";
import moment from "moment";
import cls from "./style/StyleModal.module.scss";
import imgSkip from "../../assets/images/modal/skip.svg";
import SelectInput from "components/select-input/SelectInput";
import {
  defect_brace_damage,
  defect_frame_damage,
  defect_side_damage,
  defect_sidewall_damage,
  defect_tread_damage,
  reason,
} from "./modal-components/utilDefect";
import ModalInput from "components/ui/Inputs/ModalInput";
import imgFile from "../../assets/images/UploadFileIcon/Vector.svg";
import delNoActiv from "../../assets/images/UploadFileIcon/delteNoAction.svg";
import ModalTextarea from "components/ui/Textarea/ModalTextarea";
import ValidationDelete from "./modal-components/UploadFile/ValidationDelete";
import truncateFilename from "./modal-components/funcFileName";
import toast from "react-hot-toast";
import api from "api";

const ModalUtilizationTyre: React.FC = () => {
  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const filePicker = useRef<any>(null);
  const [delValidation, setDelValidation] = useState<any>(null);
  const { skladTyre, id_tyre_moving } = useTypedSelector<any>((state) => state.tyres);
  const { toggleModal, addAction, closeActionModal } = useActions();
  const [selectFile, setSelectFile] = useState<any>([]);
  const [infoUtil, setInfoUtil] = useState<any>();
  const [skip, setSkip] = useState<boolean>(false);
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [type_reason, setTypeReason] = useState<any>();
  const [type_defect, setTypeDefect] = useState<any>();
  const [actionTypes, setActionTypes] = useState<any>("");
  const [noDefect, setNodefect] = useState<any>("");
  const [treadDamage, setTreadDamage] = useState<any>("");
  const [sidewallDamage, setSidewallDamage] = useState<any>("");
  const [frameDamage, setFrameDamage] = useState<any>("");
  const [sideDamage, setSideDamage] = useState<any>("");
  const [braceDamage, setBraceDamage] = useState<any>("");
  const [form, setForm] = useState<any>({
    tyre_id: "",
    reason: "",
    date: to,
    tread: "",
    run: 0,
    comment: "",
    uploaded_file: "",
  });

  const runTyre = infoUtil?.map((el: any) => el?.action?.run)[0];

  useEffect(() => {
    if (skladTyre !== null) {
      setInfoUtil(skladTyre?.filter((el: any) => el.id === id_tyre_moving));
    } else {
      setInfoUtil([]);
    }
  }, [id_tyre_moving]);

  useEffect(() => {
    setActionTypes(reason);
    setTreadDamage(defect_tread_damage);
    setSidewallDamage(defect_sidewall_damage);
    setFrameDamage(defect_frame_damage);
    setSideDamage(defect_side_damage);
    setBraceDamage(defect_brace_damage);
    setNodefect([]);
  }, []);

  const onChangeTypeReason = (value: string) => {
    setTypeReason(value);
  };
  const onChangeTypeDefect = (value: string) => {
    setTypeDefect(value);
  };
  const handleSkip = () => {
    setSkip(!skip);
  };
  const clickDelete = (e: any) => {
    setDelValidation(e);
  };
  const closeValidDelete = () => {
    setDelValidation(null);
  };
  const handleDelete = (e: any) => {
    setSelectFile(selectFile?.filter((_: any, i: any) => i !== e));
    setDelValidation(null);
  };
  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_utilization_tyre" });
    setTo(moment().format("YYYY-MM-DD"));
    setSkip(false);
  };

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleChangeUpload = (e: any) => {
    setSelectFile([...selectFile, e.target.files[0]]);
  };

  const handlePick = (e: any) => {
    e.preventDefault();
    setDelValidation(null);
    filePicker.current.click();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    selectFile.forEach((file: any) => {
      formData.append("uploaded_file[]", file);
    });
    formData.append("reason", type_reason);
    formData.append("comment", form.comment);
    formData.append("type", "utilization");
    formData.append("run", runTyre);
    formData.append("tread", form.tread);
    formData.append("defect", type_defect);
    formData.append("date", to);
    formData.append("tyre_id", id_tyre_moving);
    addAction(formData, "utilization");
    if (id_tyre_moving) {
      api.putTyre(id_tyre_moving, { utilization: 1 });
    }
    closeActionModal(true);
    toast.success("Данные успешно добавлены");
    handleClose();
  };

  return (
    <>
      <form id="util_tyre" onSubmit={handleSubmit}>
        <ModalWindow
          isShow={modals.modal_utilization_tyre}
          title="Утилизация шины"
          dialogClassName="modal_box-big"
          handleClose={handleClose}
        >
          <div>
            <p className="model-title__bold">Дата события</p>
            <CustomDatePicker onChange={(date) => setTo(date)} date={to} />
          </div>
          <div className={cls.ctn_model_title} onClick={handleSkip}>
            <p className={(cls.model_title, "model-title__bold")}>Информация о шине</p>
            <div>
              <img
                className={`${cls.imgSkip} ${skip ? cls.rotate : ""}`}
                src={imgSkip}
                alt="skip"
              />
            </div>
          </div>
          {skip &&
            infoUtil?.map((el: any) => (
              <div key={el.id}>
                <div className={cls.ctnInfoTyres}>
                  <div className={cls.infoData}>
                    <p className={cls.title}>Бренд:</p>
                    <div className={cls.data}>{el.brand}</div>
                  </div>
                  <div className={cls.infoData}>
                    <p className={cls.title}>Размер:</p>
                    <div>{el.size}</div>
                  </div>
                  <div className={cls.infoData}>
                    <p className={cls.title}>Пробег:</p>
                    <div>{el.run_start}</div>
                  </div>
                  <div className={cls.infoData}>
                    <p className={cls.title}>Глубина протектора:</p>
                    <div>{el.tread_start}</div>
                  </div>
                </div>
              </div>
            ))}
          <div>
            <p className="model-title__bold">Параметры утилизации</p>
            <div>
              <p className={cls.title}>Причина</p>
              <SelectInput
                value={type_reason}
                options={actionTypes}
                onChange={onChangeTypeReason}
                className="react-select--mini react-select--margin "
                placeholder="Выберите событие"
              />
            </div>
            <div>
              <p className={cls.title}>Дефект</p>
              {type_reason === undefined && (
                <SelectInput
                  value={type_defect}
                  options={noDefect}
                  onChange={(e) => onChangeTypeDefect(e)}
                  className="react-select--mini react-select--margin "
                  placeholder="Выберите причину"
                />
              )}
              {type_reason === "Повреждения протектора" && (
                <SelectInput
                  value={type_defect}
                  options={treadDamage}
                  onChange={(e) => onChangeTypeDefect(e)}
                  className="react-select--mini react-select--margin "
                  placeholder="Выберите событие"
                />
              )}
              {type_reason === "Повреждения боковины" && (
                <SelectInput
                  value={type_defect}
                  options={sidewallDamage}
                  onChange={(e) => onChangeTypeDefect(e)}
                  className="react-select--mini react-select--margin "
                  placeholder="Выберите событие"
                />
              )}
              {type_reason === "Разрушения брекера" && (
                <SelectInput
                  value={type_defect}
                  options={frameDamage}
                  onChange={(e) => onChangeTypeDefect(e)}
                  className="react-select--mini react-select--margin "
                  placeholder="Выберите событие"
                />
              )}
              {type_reason === "Повреждения каркаса" && (
                <SelectInput
                  value={type_defect}
                  options={sideDamage}
                  onChange={(e) => onChangeTypeDefect(e)}
                  className="react-select--mini react-select--margin "
                  placeholder="Выберите событие"
                />
              )}
              {type_reason === "Повреждения борта" && (
                <SelectInput
                  value={type_defect}
                  options={braceDamage}
                  onChange={(e) => onChangeTypeDefect(e)}
                  className="react-select--mini react-select--margin "
                  placeholder="Выберите событие"
                />
              )}
            </div>
            <div>
              <p className={cls.title}>Глубина протектора (см)</p>
              <ModalInput name="tread" value={form.tread} type="number" onChange={handleInput} />
            </div>
            <div>
              <p className={cls.title}>Комментарий(120 символов)</p>
              <ModalTextarea name="comment" value={form.comment} onChange={handleInput} />
            </div>
            <div>
              <p className="model-title__bold">Документы, подтверждающие утилизацию</p>
            </div>
            <div>
              <div className={cls.ctnButtonFile}>
                <button className="modal__button" onClick={handlePick}>
                  Прикрепить файл
                </button>
                <input
                  id="myFileInput"
                  type="file"
                  ref={filePicker}
                  multiple
                  onChange={handleChangeUpload}
                  className={cls.input}
                />
              </div>
              <div className={cls.tableFile}>
                {selectFile?.map((el: any, index: any) => (
                  <div className={cls.ctnFile} key={index}>
                    <div className={cls.infoFile}>
                      <img className={cls.imgFile} src={imgFile} alt="file" />
                      <div className={cls.fileName}>{truncateFilename(el.name)}</div>
                    </div>
                    {delValidation === index ? (
                      <div className={cls.modalValid}>
                        <ValidationDelete
                          onClickYes={() => handleDelete(index)}
                          onClickNo={closeValidDelete}
                        />
                      </div>
                    ) : (
                      <div />
                    )}
                    <div onClick={() => clickDelete(index)} className={cls.basket}>
                      <img src={delNoActiv} alt="basket" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={cls.ctnButton}>
              <button
                type="submit"
                className={(cls.modalButton, "modal__button")}
                onClick={handleClose}
              >
                Отмена
              </button>
              <button form="util_tyre" type="submit" className="modal__button">
                Сохранить
              </button>
            </div>
          </div>
        </ModalWindow>
      </form>
    </>
  );
};

export default ModalUtilizationTyre;
