import React, { useEffect, useState } from "react";
import moment from "moment";
// Предполагается, что это ваш компонент
// Импортируйте ваш API
import { IEvent } from "redux/events/events_types";
import { IPoint } from "redux/map/map_types";
import { time } from "./pointsHelper";
import PointStop from "./components/PointStop";
import PointMove from "./components/PointMove";
import api from "api";
import MapComponent from "./mapOpenBallonPoint/MapComponent";
import BalloonHandler from "./mapOpenBallonPoint/BalloonHandler";

const Point = (stop: PointMoveType | PointStopType) => {
  const [address, setAddress] = useState<string | null>(null);
  const [pointId, setPointId] = useState<any>();
  const [coordinate, setCoordinate] = useState<any>([]);
  const [myMap, setMyMap] = useState<any>(null);
  const { centerMapOnCoordinates } = BalloonHandler({ myMap });
  const [addressNot, setAddressNot] = useState<any>();

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchAddresses = async (points: IPoint[]): Promise<void> => {
    for (const point of points) {
      try {
        if (point.lat !== 0 && point.long !== 0) {
          const response = await api.getAddress({ lat: point.lat, long: point.long });
          setPointId(point.id);
          setCoordinate([point.lat, point.long]);
          setAddress(response.data.address);
          setAddressNot(point);
        }
      } catch (error: any) {
        setCoordinate([point.lat, point.long]);
        setAddress("Не определен");
      }
      await delay(3000); // Задержка в 1 секунду между запросами
    }
  };
  const handleMapReady = (map: any) => {
    setMyMap(map);
  };

  useEffect(() => {
    if (stop.type === "stop" && stop.data && address == null) {
      fetchAddresses([stop.data]);
    }
  }, [stop.data, address, stop.type]);

  let start = "";
  let end = "";
  let period = "";
  let isDate = true;
  let date = "";

  if (stop.type === "stop" && stop.data) {
    start = moment(stop.data.device_created_at).utcOffset("+03:00").format("HH:mm");
    end = moment(stop.data.device_updated_at).utcOffset("+03:00").format("HH:mm");
    period = time(
      Date.parse(stop.data.device_updated_at) - Date.parse(stop.data.device_created_at)
    );

    isDate = stop.data.device_created_at.slice(0, 10) === stop.data.device_updated_at.slice(0, 10);
    date = stop.data.device_created_at.slice(0, 10) + " " + start;
  }

  if (stop.type === "stop") {
    return (
      <>
        <MapComponent onMapReady={handleMapReady} />
        <PointStop
          date={date}
          start={start}
          end={end}
          isDate={isDate}
          address={address}
          pointId={pointId}
          coordinate={coordinate}
          period={period}
          centerMapOnCoordinates={centerMapOnCoordinates}
        />
      </>
    );
  }

  if (stop.type === "move") return <PointMove {...stop} />;

  return <></>;
};

export default Point;

export interface PointMoveType {
  data: PointMoveData;
  type: "move";
  events: IEvent[];
}

export interface PointStopType {
  data: IPoint;
  type: "stop";
}

export interface PointMoveData {
  id: number | null;
  time_finish: string;
  time_start: string;
  run_start: number;
  run_finish: number;
  run_total: number;
}
