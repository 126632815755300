import React from "react";
import Pressure from "assets/svgElements/Pressure";
import PressureMedium from "assets/svgElements/PressureMedium";
import Speed from "assets/svgElements/Speed";
import Temp from "assets/svgElements/Temp";

export function useSensorWarning() {
  const warningBarMin = ({
    eventValue,
    limitBarMin,
    eventIndex,
  }: {
    eventValue: number;
    limitBarMin: number;
    eventIndex: number;
  }): WarningData => {
    const diff = limitBarMin - eventValue;
    const isValue = diff > 1;

    return {
      icon: isValue ? (
        <div className="violation__icon">
          <Pressure />
        </div>
      ) : (
        <div className="violation__icon">
          <PressureMedium />
        </div>
      ),
      text: isValue ? "Давление меньше допустимого" : "Давление в пределах допустимого",
      sensor: `Датчик №${eventIndex + 1}`,
    };
  };

  const warningBarMax = ({
    eventValue,
    limitBarMax,
    eventIndex,
  }: {
    eventValue: number;
    limitBarMax: number;
    eventIndex: number;
  }): WarningData => {
    const diff = limitBarMax - eventValue;
    const isValue = diff > 1;

    return {
      icon: isValue ? (
        <div className="violation__icon">
          <Pressure />
        </div>
      ) : (
        <div className="violation__icon">
          <PressureMedium />
        </div>
      ),
      text: isValue ? "Давление больше допустимого" : "Давление в пределах допустимого",
      sensor: `Датчик №${eventIndex + 1}`,
    };
  };
  const warningTempMax = ({ eventIndex }: { eventIndex: number }): WarningData => {
    return {
      icon: (
        <div className="violation__icon">
          <Temp />
        </div>
      ),
      text: "Нарушение температуры",
      sensor: `датчик №${eventIndex + 1}`,
    };
  };

  const warningSpeedMax = ({
    eventValue,
    limitSpeedMax,
  }: {
    eventValue: number;
    limitSpeedMax: number;
    eventIndex: number;
  }): WarningData => {
    const diff = eventValue - limitSpeedMax;
    return {
      icon: (
        <div className="violation__icon">
          <Speed />
        </div>
      ),
      text: "Превышение скорости",
      sensor: `на ${diff} км/ч`,
    };
  };

  const defaultWarning = (): WarningData => {
    return {
      text: "Неопознанное нарушение",
      sensor: "Неизвестный датчик",
    };
  };

  return { warningBarMin, warningBarMax, warningTempMax, warningSpeedMax, defaultWarning };
}

type WarningData = {
  icon?: React.ReactNode;
  text: string;
  sensor: string;
};
