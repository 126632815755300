import { useTypedSelector } from "hooks/useTypedSelector";
import { SettingsState } from "redux/settings/settings_types";
import { useSensorWarning } from "./hooks/use-sensor-warning";

const ViolationPart = (event: any) => {
  let data;

  const { settingsLast } = useTypedSelector<SettingsState>((state) => state.settings);
  const { defaultWarning, warningBarMin, warningBarMax, warningTempMax, warningSpeedMax } =
    useSensorWarning();

  switch (event.type) {
    case "bar_max":
      data = warningBarMax({
        eventValue: event.value,
        limitBarMax: settingsLast.bar_max,
        eventIndex: event.index,
      });
      break;
    case "bar_min":
      data = warningBarMin({
        eventValue: event.value,
        limitBarMin: settingsLast.bar_min,
        eventIndex: event.index,
      });
      break;
    case "speed_max":
      data = warningSpeedMax({
        eventValue: event.value,
        limitSpeedMax: settingsLast.speed_max,
        eventIndex: event.index,
      });
      break;
    case "temp_max":
      data = warningTempMax({ eventIndex: event.index });
      break;
    default:
      data = defaultWarning();
  }

  return (
    <div className="violation__part">
      {data?.icon}
      <div className="violation__info">
        <p className="violation__name">{data?.text}</p>
        <div className="violation__more">
          <p className="violation__sensor">{data?.sensor}</p>
          <p className="violation__value">
            {event.value} {event.units}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViolationPart;
