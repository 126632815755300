import { useEffect, useState } from "react";
import SkeletonLoading from "../../skeleton-loading/SkeletonLoading";
import { VehicleAddress } from "../Cars.styled";

const CarAddress = ({
  address: initialAddress,
}: {
  address: any; ///undefined | null | string |
  classAddress?: string;
}) => {
  const [address, setAddress] = useState<string | null | undefined>(initialAddress);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const LoadAddress = () => {
      if (initialAddress === undefined || initialAddress === null) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
        setAddress(initialAddress);
      }
    };
    LoadAddress();
  }, [initialAddress]);

  if (address === null) {
    return (
      <VehicleAddress>
        <SkeletonLoading height="10px" />
      </VehicleAddress>
    );
  }

  if (isLoading)
    return (
      <VehicleAddress>
        <SkeletonLoading height="10px" />
      </VehicleAddress>
    );
  /// раскоментировать когда будет решен вопрос с отображение адреса

  return <VehicleAddress>{address}</VehicleAddress>;
};

export default CarAddress;
