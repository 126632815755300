import { useActions } from "hooks/useAction";
import { useTypedSelector } from "hooks/useTypedSelector";
import { TyresState } from "redux/tyres/tyres_types";
import { useEffect, useState } from "react";
import Loader from "components/loader/Loader";
import TyreTitle from "components/tyres/TyreTitle";
import TyreInfo from "components/tyres/TyreInfo";
import moment from "moment";
import TyreList from "./components/TyreList";

const TyresStorePage = () => {
  const titles = [
    "ШИНА",
    "ДАТА УСТАНОВКИ / ПЕРЕМЕЩЕНИЯ",
    "ПРИЧИНА ПЕРЕМЕЩЕНИЯ",
    "ПРОБЕГ",
    "СТОИМОСТЬ (1 КМ ПРОБЕГА)",
    "",
  ];

  const { postTyresByPeriod, getActionsPeriod } = useActions();
  const [count, setCount] = useState<any>(0);
  const [countTyres, setCountTyres] = useState<any>(0);
  const { tyres_by_period, loading_tyres }: TyresState = useTypedSelector((state) => state.tyres);
  const { actions } = useTypedSelector<any>((state) => state.actions);
  const today = moment(new Date()).format("YYYY-MM-DD");
  const { closeAction } = useTypedSelector((state) => state.actions);
  const tyres = tyres_by_period?.tyres.filter(
    (el) => el.vehicle_id === null && el?.utilization != 1
  );
  const countArray = tyres?.map((el: any) => el.action.run);
  const sumCount = countArray?.reduce((acc, value) => {
    return acc + value;
  }, 0);

  // const filterTypeTyres = (tyres: any, actions: any) => {
  //   if (!tyres || !actions || !actions.actions) return []; // Проверка на наличие данных

  //   // Фильтруем действия
  //   const filteredActions = actions.actions.filter(
  //     (el: any) =>
  //       el.type !== "utilization" &&
  //       el.type !== "adding" &&
  //       tyres.some((tyre: any) => tyre.id === el.tyre_id) &&
  //       el.type === "store"
  //   );

  //   // Находим максимальную дату updated_at
  //   const maxDate = filteredActions.reduce((max: Date | null, el: any) => {
  //     const updatedAt = new Date(el.updated_at);
  //     return max === null || updatedAt > max ? updatedAt : max;
  //   }, null);

  //   // Возвращаем только действия с максимальной датой
  //   filteredActions.filter((el: any) => new Date(el.updated_at).getTime() === maxDate?.getTime());

  //   return [...filteredActions];
  // };

  // console.log(tyres);

  const init = () => {
    postTyresByPeriod(today, today, "store");
    getActionsPeriod(today, today);
  };
  useEffect(() => {
    init();
  }, [closeAction]);

  useEffect(() => {
    setCount(sumCount);
    setCountTyres(tyres?.length);
  }, [tyres_by_period?.tyres]);

  const handlClick = () => {
    console.log("click");
  };

  return (
    <div>
      <div className="report_head">
        {loading_tyres ? (
          <Loader />
        ) : (
          <>
            <TyreTitle title="Склад" subtitle="б/у шин" handleClick={handlClick} />
            <TyreInfo
              firstCount={{
                count: countTyres,
                title1: "количество",
                title2: "шин",
              }}
              secondCount={{
                count: count,
                title1: "общий",
                title2: "пробег",
              }}
              showConstructor={true}
            />
            <TyreTitle title="Шины" subtitle="б/у" />
            <TyreList
              from={
                tyres_by_period && tyres_by_period.from
                  ? tyres_by_period.from
                  : moment(new Date()).format("YYYY-MM-DD")
              }
              to={tyres_by_period?.to}
              titles={titles}
              tyres={tyres ? tyres : []}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TyresStorePage;
