import { models } from "../../../helpers/constructorModels";
import { useState, useEffect, MouseEvent } from "react";
import { trailers } from "helpers/constructorTrailers";
import cls from "./style/GeneralTyrePos.module.scss";
import { categories } from "helpers/constructorCategories";
import { trailer_category } from "helpers/trailers";
import { useTypedSelector } from "hooks/useTypedSelector";
import ReactDOM from "react-dom";

const GeneralTyrePos = ({
  car,
  tyres_by_car,
  modelId,
  trailerId,
  handlePositions,
  defaultPositions,
  tyres,
}: {
  car: any;
  tyres_by_car: any;
  modelId: number;
  trailerId: null | number;
  wheelCount: number;
  handlePositions: (positions: number[]) => void;
  defaultPositions: any;
  tyres: any;
}) => {
  const [tyrePositions, setTyrePositions] = useState<number[]>([]);
  const modelAxes = [...models[modelId].axes].reverse();
  const trailerAxes = trailerId !== null ? [...trailers[trailerId].axes].reverse() : [];
  const categoryID = models[modelId].category;
  const imgColorTC = categories.filter((el) => el.id === categoryID).map((el) => el.image)[0];
  const isEditable = tyrePositions.length !== tyres?.length;
  const titleTrailer = trailerId !== null ? trailers[trailerId].title.slice(0, 15) : "";
  const [togglePosition, setTogglePosition] = useState<{ top: number; left: number } | null>(null);
  const [tyreIndex, serTyreIndex] = useState<any>(null);
  const [sensor, setSensor] = useState<any>([]);
  const img_color_trailer = trailer_category
    .filter((el) => el.title.includes(titleTrailer)) //// && el.type === typeTrailer
    .map((el) => el.images)[0];
  const indexClick = car?.last_point?.sensors;
  const sensorPositions = car?.design.positions;

  useEffect(() => {
    setTyrePositions(defaultPositions);
  }, [defaultPositions]);

  const onWheelClick = (wIndex: number, e: MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setTogglePosition({
      top: rect.top + window.scrollY - 37,
      left: rect.left + window.scrollX,
    });
    handleTyreClick(wIndex);
  };

  const getSensorData = (sensor: any, position: any, tyreIndex: any) => {
    if (!sensor || !position) return;
    const sensorIndex = position.indexOf(tyreIndex);

    if (sensorIndex === -1) {
      return (
        <>
          <div className={cls.textSensor}>Датчики не найдены</div>
        </>
      );
    }
    const [pressure, temperature] = sensor[sensorIndex];

    return (
      <>
        <div className={cls.toggleContent}>
          <div className={cls.textSensor}>
            Давление &nbsp; <strong>{pressure} Bar</strong>
          </div>
          <div className={cls.textSensor}>
            Температура &nbsp; <strong>{temperature} °</strong>
          </div>
        </div>
      </>
    );
  };

  const resultDataSensor = getSensorData(indexClick, sensorPositions, tyreIndex);

  const getWheelByIndex = (index: any) => {
    if (tyrePositions?.indexOf(index) === -1) return null;
    return tyrePositions?.indexOf(index) + 1;
  };

  let wheelIndex = 0;

  const handleTyreClick = (index: number) => {
    serTyreIndex(index);
    if (indexClick === undefined) {
      setSensor([]);
    } else {
      setSensor(indexClick[index - 1]);
    }
  };

  const axesListModel = modelAxes.map((axis, axisIndex) => {
    const wheels = axis.wheels.map((offset) => {
      wheelIndex++;
      const wIndex = wheelIndex;
      const content = getWheelByIndex(wIndex);

      const classes = ["wheel"];
      if (axis.mini) classes.push("wheel--mini_tyres");
      if (isEditable && !content) classes.push("wheel--editable");
      classes.push(!content ? "wheel--empty" : "wheel--color_filled");
      classes.push(tyreIndex === wIndex ? "wheel--empty--hover" : "wheel--empty");

      return (
        <div
          className="wheel-container-general_tc"
          style={{ transform: `translate(0, ${offset}px)` }}
          key={wIndex}
          onMouseOut={(e) => onWheelClick(wIndex, e)}
          onMouseLeave={() => serTyreIndex(null)}
        >
          {tyreIndex === wIndex ? (
            <div className={classes.join(" ")}>
              {!content && <span className="hidden-index">{tyrePositions.length + 1}</span>}
            </div>
          ) : (
            <div className={classes.join(" ")} key={wIndex}>
              {!content && <span className="hidden-index">{tyrePositions.length + 1}</span>}
            </div>
          )}

          {tyreIndex === wIndex &&
            ReactDOM.createPortal(
              <div
                className={`${cls.toggle} ${tyreIndex === wIndex ? cls.active : ""}`}
                style={{
                  position: "absolute",
                  top: togglePosition?.top || 0,
                  left: togglePosition?.left || 0,
                  zIndex: 10,
                }}
              >
                {resultDataSensor}
              </div>,
              document.body
            )}
        </div>
      );
    });

    return (
      <div className="axis" key={axisIndex} style={{ transform: `translate(${axis.offset}px, 0)` }}>
        {wheels}
      </div>
    );
  });

  const axesListTrailer = trailerAxes.map((axis, axisIndex) => {
    const wheels = axis.wheels.map((offset) => {
      wheelIndex++;
      const wIndex = wheelIndex;
      const content = getWheelByIndex(wIndex);

      const classes = ["wheel"];
      if (axis.mini) classes.push("wheel--mini");
      if (isEditable && !content) classes.push("wheel--editable");
      classes.push(!content ? "wheel--empty" : "wheel--color_filled");

      return (
        <div
          className="wheel-container-general_trailer"
          style={{ transform: `translate(0, ${offset}px)` }}
          key={wIndex}
        >
          <div className={classes.join(" ")}>
            {!content && <span className="hidden-index">{tyrePositions.length + 1}</span>}
          </div>
        </div>
      );
    });

    return (
      <div className="axis" key={axisIndex} style={{ transform: `translate(${axis.offset}px, 0)` }}>
        {wheels}
      </div>
    );
  });

  return (
    <div className="view-model__container">
      <div className="view-model">
        <img src={imgColorTC} alt="alt" style={{ transform: "scale(1.4)" }} />
        {tyrePositions.length > 0 ? (
          <div
            className="wheels"
            // style={{ pointerEvents: "none" }}
            // onMouseOver={(e) => toggleInfo(e)}
          >
            {axesListModel}
            {/* <div className={cls.ctnToltip}></div> */}
          </div>
        ) : (
          <div className="wheels" style={{ pointerEvents: "none" }}>
            {axesListModel}
          </div>
        )}
      </div>

      {trailerId && (
        <div
          className="view-model view-model--trailer"
          style={{
            padding: `${trailers[trailerId].globalPadding}px 0`,
            marginRight: "80px",
          }}
        >
          {/* {trailers[trailerId].flat} */}
          <img src={img_color_trailer} alt="trailer" style={{ transform: "scale(2.5)" }} />
          {tyrePositions.length > 0 ? (
            <div
              className="wheel"
              style={{ flexDirection: "row-reverse", position: "absolute", pointerEvents: "none" }}
            >
              {axesListTrailer}
            </div>
          ) : (
            <div
              className="wheel"
              style={{ flexDirection: "row-reverse", position: "absolute", pointerEvents: "none" }}
            >
              {axesListTrailer}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GeneralTyrePos;
