import React, { useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import ModalInput from "../ui/Inputs/ModalInput";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import ModalWindow from "./ModalWindow";
import toast from "react-hot-toast";
import VehicleHeader from "components/vehicle-view/VehicleHeader";
import ModalTextarea from "components/ui/Textarea/ModalTextarea";

const ModalAddTransport = () => {
  const [carNumber, setCarNumber] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [position_tyres, setPositionTyres] = useState<number[]>([]);
  const [code, setCode] = useState<string>("");
  const [errors, setErrors] = useState<any>({
    title: [],
    region: [],
    car_number: [],
    comment: [],
    code: [],
  });

  const { toggleModal, addCar } = useActions();

  const [submiting, setSubmiting] = useState<boolean>(false);

  const modals: ModalsState = useTypedSelector((state) => state.modals);

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_add_transport" });
    setCarNumber("");
    setRegion("");
    setTitle("");
    setComment("");
    setCode("");
    setErrors({
      title: [],
      region: [],
      car_number: [],
      code: [],
    });
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const data = {
      title,
      car_number: carNumber,
      comment,
      region,
      code,
      position_tyres,
    };

    try {
      setSubmiting(true);
      await addCar(data);
      toast.success("Вы успешно добавили транспорт");
      handleClose();
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        setErrors({
          ...errors,
          title: error?.response?.data?.errors?.title || [],
          car_number: error?.response?.data?.errors?.car_number || [],
          code: error?.response?.data?.errors?.code || [],
          region: error?.response?.data?.errors?.region || [],
        });
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setSubmiting(false);
  };

  if (submiting) {
    return (
      <ModalWindow
        isShow={modals.modal_add_transport}
        title="Добавление транспортного средства"
        handleClose={handleClose}
        dialogClassName="modal__box-big"
      >
        <SkeletonLoading height="382px" />
      </ModalWindow>
    );
  }

  return (
    <ModalWindow
      isShow={modals.modal_add_transport}
      title="Добавление транспортного средства"
      handleClose={handleClose}
      dialogClassName="modal__box-big"
    >
      <form id="addTransport" onSubmit={handleSave} className="modal-content__box">
        <div className="modal__inputs-box">
          <ModalInput
            titleClass="modal-password_title"
            title="Гос номер"
            length={7}
            value={carNumber}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setCarNumber((e.target as HTMLInputElement).value)
            }
            errors={errors.car_number}
          />
          <ModalInput
            titleClass="modal-password_title"
            title="Регион"
            length={3}
            value={region}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setRegion((e.target as HTMLInputElement).value)
            }
            errors={errors.region}
          />
        </div>
        <div className="modal__inputs-box">
          <ModalInput
            titleClass="modal-password_title"
            title="Марка ТС"
            length={32}
            value={title}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setTitle((e.target as HTMLInputElement).value)
            }
            errors={errors.title}
          />
          <ModalInput
            titleClass="modal-password_title"
            title="Код активации"
            value={code}
            length={16}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setCode((e.target as HTMLInputElement).value)
            }
            errors={errors.code}
          />
        </div>

        <ModalTextarea
          titleClass="modal-password_title"
          title="Комментарий"
          value={comment}
          length={64}
          rowsLength={2}
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
            setComment((e.target as HTMLTextAreaElement).value)
          }
          errors={errors.comment}
        />
      </form>

      <div className="preview_box">
        <h4 className="modal-password_title">Превью</h4>
        <div className="preview__border"></div>
        {(carNumber || region || comment.trim() || title) && (
          <VehicleHeader
            car={{
              title,
              car_number: carNumber.toUpperCase(),
              comment: comment.trim(),
              region,
              code,
            }}
          />
        )}
      </div>

      <div className="modal-edit__button-box">
        <button form="addTransport" type="submit" className="modal__button">
          Сохранить
        </button>
      </div>
    </ModalWindow>
  );
};

export default ModalAddTransport;
