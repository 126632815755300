import cls from "../../style/StyleModal.module.scss";

const ValidationDelete: React.FC<any> = ({ onClickYes, onClickNo }) => {
  return (
    <>
      <div className={cls.ctnValidation}>
        <div className={cls.text}>Удалить файл?</div>
        <div className={cls.ctnButtonDelete}>
          <button type="button" onClick={onClickYes} className={cls.buttonDeleteNo}>
            да
          </button>
          <button onClick={onClickNo} className={cls.buttonDeleteNo}>
            нет
          </button>
        </div>
      </div>
    </>
  );
};

export default ValidationDelete;
