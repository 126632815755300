import { ActionActionTypes, ActionsActions, ActionsState } from "./actions_types";

const initialState: ActionsState = {
  actions: null,
  filter: null,
  loading: true,
  period: null,
  visible: false,
  type: "all",
  reset: false,
  countRun: 0,
  type_input: "",
  select_value_util: "all",
  select_type_journal: "all",
  closeAction: false,
  detail_repair: null,
  axel_action_all: null,
};

const ActionReducer = (state = { ...initialState }, action: ActionsActions) => {
  switch (action.type) {
    case ActionActionTypes.GET_ACTIONS:
      return {
        ...state,
        actions: action.payload,
      };
    case ActionActionTypes.ACTION_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case ActionActionTypes.ACTIONS_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case ActionActionTypes.CHOOSING_PERIOD:
      return {
        ...state,
        period: action.payload,
      };
    case ActionActionTypes.VISIBLE_ACTIONS:
      return {
        ...state,
        visible: action.payload,
      };
    case ActionActionTypes.TYPE_OF_EVENT_ACTIONS:
      return {
        ...state,
        type: action.payload,
      };
    case ActionActionTypes.RESET_ACTION:
      return {
        ...state,
        reset: action.payload,
      };
    case ActionActionTypes.INPUT_TYPE:
      return {
        ...state,
        type_input: action.payload,
      };
    case ActionActionTypes.SELECT_TYPE_UTIL:
      return {
        ...state,
        select_value_util: action.payload,
      };
    case ActionActionTypes.SELECT_TYPE_JOURNAL_EVENT:
      return {
        ...state,
        select_type_journal: action.payload,
      };
    case ActionActionTypes.CLOSE:
      return {
        ...state,
        closeAction: action.payload,
      };
    case ActionActionTypes.CLOSE_RESET:
      return {
        ...state,
        closeAction: initialState.closeAction, // Сбрасываем только closeAction
      };
    case ActionActionTypes.DETAIL_TYRE_REPAIR:
      return {
        ...state,
        detail_repair: action.payload,
      };
    case ActionActionTypes.GET_AXEL_ACTION_ALL:
      return {
        ...state,
        axel_action_all: action.payload,
      };
    default:
      return state;
  }
};

export default ActionReducer;
