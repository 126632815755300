import { models } from "../../../helpers/constructorModels";
import { useState, useEffect } from "react";
import { trailers } from "helpers/constructorTrailers";
import cls from "./style/GeneralTyrePos.module.scss";
import { categories } from "helpers/constructorCategories";
import { trailer_category } from "helpers/trailers";
import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useAction";
import api from "api";
import Confirmation from "./components/Confirmation";
import { useParams } from "react-router-dom";

const MovingTyreInTs = ({
  run,
  tread,
  tyre_id,
  car,
  modelId,
  trailerId,
  handlePositions,
  defaultPositions,
  tyres,
}: {
  run: any;
  tread: any;
  tyre_id: any;
  car: any;
  modelId: number;
  trailerId: null | number;
  wheelCount: number;
  handlePositions: (positions: number[]) => void;
  defaultPositions: any;
  tyres: any;
}) => {
  const [tyreId, setTyreId] = useState<any>(tyre_id);
  const { carTyrePosition, getTyre, addAction } = useActions();
  const { tyres_by_car, tyre } = useTypedSelector<any>((state) => state.tyres);
  const [tyrePositions, setTyrePositions] = useState<number[]>([]);
  const [selectedTyre, setSelectedTyre] = useState<number | null>(null);
  const [swapRequest, setSwapRequest] = useState<any>(null);
  const [swapTyreId, setSwapTyreID] = useState<number>(0);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [tyreIndex, serTyreIndex] = useState<any>(null);
  const [sensor, setSensor] = useState<any>([]);
  // const [highlightPosition, setHeightlightPosition] = useState<number | null>(null);
  const parsPosition = JSON.parse(tyre?.position_tyre);
  const indexClick = car?.last_point?.sensors;
  const modelAxes = [...models[modelId].axes].reverse();
  const trailerAxes = trailerId !== null ? [...trailers[trailerId].axes].reverse() : [];
  const categoryID = models[modelId].category;
  const imgColorTC = categories.filter((el) => el.id === categoryID).map((el) => el.image)[0];
  const isEditable = tyrePositions.length !== tyres?.length;
  const titleTrailer = trailerId !== null ? trailers[trailerId].title.slice(0, 15) : "";
  const img_color_trailer = trailer_category
    .filter((el) => el.title.includes(titleTrailer))
    .map((el) => el.images)[0];
  const processTyres = (positions: any) => {
    if (Array.isArray(positions)) {
      return positions;
    } else if (typeof positions === "string") {
      return positions
        .replace(/^\[|\]$/g, "")
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item !== "null" && item !== "")
        .map(Number);
    } else {
      console.error("Invalid input: expected a string or an array");
      return [];
    }
  };

  const getWheelByIndex = (index: any) => {
    if (tyrePositions?.indexOf(index) === -1) return null;
    return tyrePositions?.indexOf(index) + 1;
  };
  useEffect(() => {
    setTyrePositions(processTyres(defaultPositions));
  }, [defaultPositions]);
  // useEffect(() => {
  //   // console.log("New tyre positions:", tyrePositions);
  // }, [tyrePositions]);

  const filterPosition = (position: number, tyres: any) => {
    return tyres
      .filter((tyre: any) => {
        let parsedPositions;
        try {
          parsedPositions = JSON.parse(tyre.position_tyre);
        } catch (e) {
          console.error("Ошибка парсинга JSON:", e);
          return false;
        }
        if (!Array.isArray(parsedPositions)) {
          parsedPositions = [parsedPositions];
        }
        return parsedPositions.includes(position);
      })
      .map((el: any) => el.id)[0];
  };

  let wheelIndex = 0;
  const replaceNumber = (arr: any, oldNum: any, newNum: any) => {
    console.log("arr>>", arr, "oldNum>>", oldNum, "newNum>>", newNum);

    const index = arr.indexOf(oldNum); // Find the index of the old number
    if (index !== -1) {
      arr[index] = newNum; // Replace old number with new one
    }
    return arr;
  };
  const handleTyreClick = (index: number) => {
    if (selectedTyre === null) {
      console.log("index1>>", index);

      // Выбираем первую шину
      setSelectedTyre(index);
    } else if (selectedTyre !== index) {
      console.log("index2>>", index);

      // Выбираем вторую шину для обмена
      setSwapRequest(index);
      setShowConfirm(true);
    }
  };
  // console.log("selectedTyre", selectedTyre);
  // console.log("swapRequest", swapRequest);

  const swapTyres = async (e: any) => {
    e.preventDefault();
    if (selectedTyre !== null && swapRequest !== null) {
      const newPositions = [...tyrePositions];
      const newOnePosition = [...tyrePositions];
      const maxIndex = tyrePositions.length - 1;
      const onePosition = replaceNumber(newOnePosition, selectedTyre, swapRequest);
      const selectedTyreIndex = newPositions.indexOf(selectedTyre);
      const swapTyreIndex = newPositions.indexOf(swapRequest);
      [newPositions[selectedTyreIndex], newPositions[swapTyreIndex]] = [
        newPositions[swapTyreIndex],
        newPositions[selectedTyreIndex],
      ];
      if (selectedTyreIndex === -1 || swapTyreIndex === -1) {
        try {
          if (car !== undefined) {
            await api.putCar(car, {
              position_tyres: onePosition,
            });
            carTyrePosition(car, onePosition);
          } else {
            console.log("Car ID is undefined");
          }
          if (tyreId && swapRequest !== null) {
            await api.putTyre(tyreId, {
              position_tyre: [swapRequest],
            });
            getTyre(tyre_id);
            addAction(
              {
                type: "axel_change",
                run: run,
                tread: tread,
                vehicle_id: car,
                tyre_id: tyre_id,
                position_starting: [selectedTyre],
                position_ending: [swapRequest],
              },
              "axel_change"
            );
          } else {
            console.log("Tyre ID is undefined");
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        const newSwapTyreId = filterPosition(swapRequest, tyres_by_car);

        setSwapTyreID(newSwapTyreId);
        setTyrePositions(newPositions);
        carTyrePosition(car, newPositions);

        try {
          if (car !== undefined) {
            await api.putCar(car, {
              position_tyres: newPositions,
            });
          } else {
            console.log("Car ID is undefined");
          }
          const firstRequestResponse = await api.putTyre(tyre_id, {
            position_tyre: [swapRequest],
          });
          if (firstRequestResponse && firstRequestResponse.status === 200) {
            const secondRequestResponse = await api.putTyre(newSwapTyreId, {
              position_tyre: [selectedTyre],
            });
            addAction(
              {
                type: "axel_change",
                run: run,
                tread: tread,
                vehicle_id: car,
                tyre_id: tyre_id,
                position_starting: [selectedTyre],
                position_ending: [swapRequest],
              },
              "axel_change"
            );
            getTyre(tyre_id);
            console.log("parsPositionNew>>>>>", parsPosition);
            console.log("Second tyre updated successfully:", secondRequestResponse);
          } else {
            console.error("First request failed, second request will not be executed.");
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    setSelectedTyre(null);
    setSwapRequest(null);
    setShowConfirm(false);
  };
  const cancelSwap = () => {
    setSelectedTyre(null);
    setSwapRequest(null);
    setShowConfirm(false);
  };
  const axesListModel = modelAxes.map((axis, axisIndex) => {
    const wheels = axis.wheels.map((offset) => {
      wheelIndex++;
      const wIndex = wheelIndex;
      const content = getWheelByIndex(wIndex);
      const classes = ["wheel"];
      if (axis.mini) classes.push("wheel--mini_tyres");
      if (isEditable && content) classes.push("wheel--editable");
      classes.push(!content ? "wheel--empty" : "wheel--color_filled");
      if (selectedTyre === wIndex) classes.push("wheel--color_filled-red");
      if (swapRequest === wIndex) classes.push("wheel--color_filled-orange");
      if (parsPosition === wIndex) classes.push("wheel--color_filled-green");
      return (
        <div
          className="wheel-container-general_tc"
          style={{ transform: `translate(0, ${offset}px)` }}
          key={wIndex}
          onClick={() => handleTyreClick(wIndex)}
          //   onMouseOut={() => handleTyreClick(wIndex)}
          //   onMouseLeave={() => serTyreIndex(null)}
        >
          {tyreIndex === wIndex ? (
            <div className={classes.join(" ")}>
              {!content && <span className="hidden-index">{tyrePositions.length + 1}</span>}
            </div>
          ) : (
            <div className={classes.join(" ")} key={wIndex}>
              {!content && <span className="hidden-index">{tyrePositions.length + 1}</span>}
            </div>
          )}

          {tyreIndex === wIndex && (
            <div className={cls.toggle}>
              <div className={cls.toggleContent}>
                {sensor && indexClick !== undefined ? (
                  <>
                    <div className={cls.textSensor}>
                      Давление &nbsp; <strong> {sensor[0]} Bar</strong>
                    </div>
                    <div className={cls.textSensor}>
                      Температура &nbsp; <strong> {sensor[1]} °</strong>
                    </div>
                  </>
                ) : (
                  <div className={cls.textSensor}>Датчики не найдены</div>
                )}
              </div>
            </div>
          )}
        </div>
      );
    });

    return (
      <div className="axis" key={axisIndex} style={{ transform: `translate(${axis.offset}px, 0)` }}>
        {wheels}
      </div>
    );
  });

  const axesListTrailer = trailerAxes.map((axis, axisIndex) => {
    const wheels = axis.wheels.map((offset) => {
      wheelIndex++;
      const wIndex = wheelIndex;
      const content = getWheelByIndex(wIndex);

      const classes = ["wheel"];
      if (axis.mini) classes.push("wheel--mini");
      if (isEditable && !content) classes.push("wheel--editable");
      classes.push(!content ? "wheel--empty" : "wheel--color_filled");
      if (parsPosition === wIndex) classes.push("wheel--color_filled-green");
      if (selectedTyre === wIndex) classes.push("wheel--color_filled-red");
      if (swapRequest === wIndex) classes.push("wheel--color_filled-orange");

      return (
        <div
          className="wheel-container-general_trailer"
          style={{ transform: `translate(0, ${offset}px)` }}
          key={wIndex}
          onClick={() => handleTyreClick(wIndex)}
        >
          {tyreIndex === wIndex ? (
            <div className={classes.join(" ")}>
              {!content && <span className="hidden-index">{tyrePositions.length + 1}</span>}
            </div>
          ) : (
            <div className={classes.join(" ")} key={wIndex}>
              {!content && <span className="hidden-index">{tyrePositions.length + 1}</span>}
            </div>
          )}
          {tyreIndex === wIndex && (
            <div className={cls.toggle}>
              <div className={cls.toggleContent}>
                {sensor && indexClick !== undefined ? (
                  <>
                    <div className={cls.textSensor}>
                      Давление &nbsp; <strong> {sensor[0]} Bar</strong>
                    </div>
                    <div className={cls.textSensor}>
                      Температура &nbsp; <strong> {sensor[1]} °</strong>
                    </div>
                  </>
                ) : (
                  <div className={cls.textSensor}>Датчики не найдены</div>
                )}
              </div>
            </div>
          )}
        </div>
      );
    });

    return (
      <div className="axis" key={axisIndex} style={{ transform: `translate(${axis.offset}px, 0)` }}>
        {wheels}
      </div>
    );
  });

  return (
    <div className="view-model__container">
      {showConfirm && <Confirmation onClickOk={swapTyres} onClickCancel={cancelSwap} />}
      <div className="view-model">
        <img src={imgColorTC} alt="alt" style={{ transform: "scale(1.4)" }} />
        {tyrePositions.length > 0 ? (
          <div
            className="wheels"
            // style={{ pointerEvents: "none" }}
            // onMouseOver={(e) => toggleInfo(e)}
          >
            {axesListModel}
            {/* <div className={cls.ctnToltip}></div> */}
          </div>
        ) : (
          <div className="wheels" style={{ pointerEvents: "none" }}>
            {axesListModel}
          </div>
        )}
      </div>

      {trailerId && (
        <div
          className="view-model view-model--trailer"
          style={{
            padding: `${trailers[trailerId].globalPadding}px 0`,
            marginRight: "80px",
          }}
        >
          {/* {trailers[trailerId].flat} */}
          <img src={img_color_trailer} alt="trailer" style={{ transform: "scale(2.5)" }} />
          {tyrePositions.length > 0 ? (
            <div
              className="wheel"
              style={{ flexDirection: "row-reverse", position: "absolute", cursor: "pointer" }}
            >
              {axesListTrailer}
            </div>
          ) : (
            <div
              className="wheel"
              style={{ flexDirection: "row-reverse", position: "absolute", pointerEvents: "none" }}
            >
              {axesListTrailer}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MovingTyreInTs;
