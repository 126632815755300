import { TyreProps } from "redux/tyres/tyres_types";
import { ReactComponent as Arrow } from "../../assets/images/Arrow2.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import { CarState } from "redux/car/car_types";
import { useTypedSelector } from "hooks/useTypedSelector";
import { ConstructorState } from "redux/constructor/constructor_types";
import TyrePositionList from "components/constructor/placementTyre/TyrePositionList";
import { useActions } from "hooks/useAction";
import cls from "./style/StyleTyreLine.module.scss";
import { useState } from "react";

const TyreLine = (tyre: Omit<TyreProps, "actions | last_move">) => {
  const { car }: CarState = useTypedSelector((state) => state.car);
  const constructor: ConstructorState = useTypedSelector((state) => state.design);
  const { modelId, trailerId, positions } = constructor;
  const [active, setActive] = useState(false);
  // const { tyres_by_car }: any = useTypedSelector((state) => state.tyres);
  // const { detailTyreRepait } = useActions();

  const handlePosition = () => {
    // console.log("click");
  };

  return (
    <div className={cls.ctnBox}>
      <div className={cls.ctnTittleTyre}>
        <div className={cls.titleModel}>{tyre.model}</div>
        <div className={cls.titleSize}>{tyre.size}</div>
      </div>
      <div className={cls.ctnDate}>{moment(tyre.production_date).format("YYYY-MM-DD")}</div>
      <div className={cls.ctnDot}>{tyre.dot}</div>
      <div className={cls.ctnConstructor}>
        {car?.design != null && modelId && car ? (
          <TyrePositionList
            modelId={modelId}
            defaultPositions={tyre.position_tyre}
            trailerId={trailerId}
            wheelCount={8}
            handlePositions={handlePosition}
            tyre={tyre.position_tyre}
          />
        ) : (
          <div>конструктор не найден</div>
        )}
      </div>

      <div className={cls.ctnRun}>
        {tyre.last_action?.run ? tyre.last_action?.run : tyre.run_start} км
      </div>
      <div className={cls.ctnTread}>{tyre.last_action?.tread} мм</div>
      {car && (
        <Link className={cls.ctnLink} to={`/tyres/${car.id}/${tyre.id}`}>
          <span
            className={`${cls.span} ${active ? cls.active : ""}`}
            onMouseMove={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
          >
            ПОДРОБНЕЕ
          </span>
          <Arrow className="arrow" />
        </Link>
      )}
    </div>
  );
};

export default TyreLine;
