import { models } from "helpers/constructorModels";
import cls from "./style/ActionItemLine.module.scss";
import moment from "moment";
import { ACTION_TYPES } from "constants/actions";
import TyrePositionList from "components/constructor/placementTyre/TyrePositionList";
import { ReactComponent as Arrow } from "../../../assets/images/Arrow2.svg";
import { useTypedSelector } from "hooks/useTypedSelector";
import JournalListTyre from "components/constructor/placementTyre/JournalListTyre";
const ActionItemLine = ({
  date,
  type,
  action_id,
  car_title,
  run,
  tread,
  model,
  trailer,
  position_tyre,
}: ActionItemLineProps) => {
  const newData = moment(date).format("DD.MM");
  const newType = ACTION_TYPES[type];
  const { axel_action_all } = useTypedSelector<any>((state) => state.actions);

  const actionAxel = (actionID: any) => {
    const item = axel_action_all?.actions.filter((el: any) => el.action_id === actionID);
    if (item?.length > 0) {
      return {
        position_ending: item[0]?.position_ending,
        position_starting: item[0]?.position_starting,
      };
    } else {
      return {
        position_ending: null,
        position_starting: null,
      };
    }
  };
  const resultAxelChange = actionAxel(action_id);
  const handlePosition = () => {};
  const handlePoint = () => {};
  return (
    <div className={cls.ctnLine}>
      <div className={cls.text}>{newData}</div>
      <div className={`${cls.text} ${cls.action}`}>{newType}</div>
      <div className={`${cls.text} ${cls.bold}`}>{car_title}</div>
      <div className={`${cls.text} ${cls.constructor}`}>
        {model && position_tyre ? (
          <JournalListTyre
            defaultPositions={resultAxelChange?.position_ending || position_tyre}
            position_ending={resultAxelChange?.position_ending}
            position_starting={resultAxelChange?.position_starting}
            modelId={model}
            trailerId={trailer}
            wheelCount={8}
            handlePositions={handlePosition}
            tyre={resultAxelChange?.position_ending || position_tyre}
          />
        ) : (
          <div>конструктор не найден</div>
        )}
      </div>
      <div className={`${cls.text} ${cls.run}`}>{run}</div>
      <div className={`${cls.text} ${cls.tread}`}>{tread}</div>
      {/* <button className={(cls.report, "report_time-more")} onClick={() => handlePoint()}>
        <span className="time-more_text">Подробнее</span>
        <Arrow className="arrow" />
      </button> */}
    </div>
  );
};

export default ActionItemLine;

export interface ActionItemLineProps {
  date: string;
  type: string;
  action_id: number;
  car_title: string | null | undefined;
  position_tyre: [];
  run: number;
  model: number;
  trailer: number;
  tread: number;
}
