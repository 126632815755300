import { ModalsState } from "redux/modals/modals_types";
import ModalWindow from "./ModalWindow";
import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useAction";
import { ReactComponent as Loupe } from "../../assets/images/loupe.svg";
import { useEffect, useState } from "react";
import cls from "./style/StyleModal.module.scss";
import TransportInfo from "./modal-components/TransportInfo";
import Checkbox from "components/ui/Checkbox/Checkbox";
import moment from "moment";
import api from "api";
import TyreEdit from "components/constructor/placementTyre/TyreEdit";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ModalInstalltionOnTransport: React.FC = () => {
  const { addAction, updateTyre, closeActionModal, closeActionReset } = useActions();
  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const cars = useTypedSelector((state) => state.cars);
  const { skladTyre, id_tyre_moving } = useTypedSelector<any>((state) => state.tyres);
  const { position_tyres } = useTypedSelector<any>((state) => state.car);
  const { closeAction } = useTypedSelector((state) => state.actions);
  const { getCars, getIdCars, toggleModal, changeFilter } = useActions();
  const [itemCar, setItemCar] = useState<any>();
  const [choice, setChoice] = useState<any>([]);
  const [infoTyre, setInfoTyre] = useState<any>();
  const [carId, setCarId] = useState<any>();
  const [tyreId, setTyreId] = useState<any>();
  const [position_tyre, setPositionTyre] = useState<any>([]);
  const [constructorCar, setConstructorCar] = useState<any>();
  const [actionId, setActionId] = useState<any>();
  const findIdCar = choice.find((el: any) => el);
  const [inputValue, setInputValue] = useState<string>();
  const [filteredMachine, setFilteredMachine] = useState<any>();
  const navigate = useNavigate();
  const [form, setForm] = useState<any>({
    tyre_id: "",
    tread: "",
    type: "moving",
    run: "",
    from: "",
    to: "",
  });

  const filtered_position = position_tyres?.filter((el: any) => el.carId === findIdCar?.id);
  const data_positions = filtered_position[0]?.positions;
  const filterCarTitle = (array: any[], titleQuery: any) => {
    return array.filter(
      (item: { title: string | any[] }) =>
        typeof item.title === "string" &&
        item.title.toLowerCase().includes(titleQuery.toLowerCase())
    );
  };

  useEffect(() => {
    getCars();
    getIdCars(findIdCar?.id);
    // getTyre(+tyre_id);
  }, [closeAction, constructorCar]);

  useEffect(() => {
    if (skladTyre) {
      setInfoTyre(skladTyre?.filter((el: any) => el.id === id_tyre_moving));
    }
  }, [id_tyre_moving]);

  const handleChange = (value: string) => {
    setItemCar(filterCarTitle(cars.cars, value));
    changeFilter(value);
    setInputValue(value);
  };

  const handlePositions = (position: any) => {
    setPositionTyre(position);
  };
  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_installTyre_onCar" });
    setInputValue("");
    setChoice([]);
    setFilteredMachine(undefined);
    cars.filter = "";
    setPositionTyre([]);
    setConstructorCar(undefined);

    closeActionReset();
  };

  const handleClickIdCars = (e: any) => {
    console.log(e);
    if (!e || !e.id) {
      console.error("Invalid event data:", e);
      return; // Прекращаем выполнение функции
    }

    try {
      const item = itemCar.find((item: any) => item.id === e.id);
      setChoice((el: any) => [...el, item]);
      setFilteredMachine(choice);
      setActionId(infoTyre[0]?.action?.id);
      setCarId(e?.id);
      setTyreId(infoTyre[0]?.id);
      setForm({
        tyre_id: id_tyre_moving,
        tread: infoTyre[0]?.action?.tread,
        type: "moving",
        run: infoTyre[0]?.action?.run,
        from: infoTyre[0]?.action?.store?.vehicle_id,
        to: e.id,
      });
      setConstructorCar(item);
      closeActionModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (carId !== undefined) {
        await api.putCar(carId, {
          position_tyres: data_positions,
        });
      } else {
        console.log("Car ID is undefined");
      }
      addAction(form, "moving");
      api.putTyre(tyreId, {
        vehicle_id: carId,
        position_tyre: position_tyre,
      });

      toast.success("Вы установили шину на ТС");
      handleClose();
    } catch (error) {
      console.log("Error>", error);
    }
  };

  return (
    <form id="tyreOnTransport" onSubmit={handleSave}>
      <ModalWindow
        isShow={modals.modal_installTyre_onCar}
        title="Установка шины на ТС"
        dialogClassName="modal_box-big"
        handleClose={handleClose}
      >
        <div>
          <p className="model-title__bold">Транспорт</p>
        </div>
        <div>
          {!filteredMachine ? (
            <>
              <div className="side-bar__search">
                <Loupe className="side-bar__img" />
                <input
                  onChange={(e) => handleChange(e.target.value)}
                  className="side-bar__input"
                  placeholder="ПОИСК ТС"
                  value={cars.filter}
                />
              </div>
              <div>
                {inputValue ? (
                  itemCar && itemCar?.length === 0 ? (
                    <div className={cls.titleSearchCar}>
                      <p className="modal-password_title">Транспорт не найден</p>
                    </div>
                  ) : (
                    <div>
                      {itemCar.map((el: any) => (
                        <div
                          key={el.id}
                          className={cls.containerCar}
                          onClick={() => handleClickIdCars(el)}
                        >
                          <TransportInfo
                            car_number={el.car_number}
                            region={el.region}
                            id={el.id}
                            title={el.title}
                          />
                        </div>
                      ))}
                    </div>
                  )
                ) : (
                  <div className={cls.titleSearchCar}>
                    <p className="modal-password_title">Введите название машины</p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div>
              {choice.map((el: any) => (
                <div key={el.id} className={cls.containerCarInstall}>
                  <TransportInfo
                    car_number={el.car_number}
                    region={el.region}
                    id={el.id}
                    title={el.title}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div>
          {constructorCar &&
          constructorCar?.design &&
          constructorCar?.design?.model_id &&
          constructorCar?.position_tyres ? (
            <>
              <hr />
              <p className="model-title__bold">Местоположение шин</p>
              <TyreEdit
                carId={constructorCar?.id}
                modelId={constructorCar?.design?.model_id}
                defaultPositions={constructorCar?.position_tyres}
                trailerId={constructorCar?.design?.trailer_id}
                wheelCount={8}
                handlePositions={handlePositions}
                tyres={constructorCar?.position_tyres} /// пробовать вытаскивать из шины
              />
            </>
          ) : (
            <></>
          )}
        </div>
        <div>
          <hr />
          <p className="model-title__bold">Модель и размеры шин</p>
        </div>
        <div style={{ marginBottom: "5px" }}>
          <div>
            {infoTyre?.map((el: any) => (
              <div key={el.id}>
                {el.used === 0 ? (
                  <Checkbox
                    text="Б/У"
                    checked={false}
                    titleClasses="modal-password_title"
                    disabled
                  />
                ) : el.used === 1 ? (
                  <Checkbox
                    checked={true}
                    text="Б/У"
                    titleClasses="modal-password_title"
                    disabled
                  />
                ) : (
                  <Checkbox
                    checked={false}
                    text="Б/У"
                    titleClasses="modal-password_title"
                    disabled
                  />
                )}
                <div className={cls.ctnModel}>
                  <div className={cls.infoData}>
                    <p className={cls.title}>Бренд:</p>
                    <div className={cls.data}>{el.brand}</div>
                  </div>
                  <div className={cls.infoData}>
                    <p className={cls.title}>Модель:</p>
                    <div className={cls.data}>{el.model}</div>
                  </div>
                  <div className={cls.infoData}>
                    <p className={cls.title}>Размер:</p>
                    <div className={cls.data}>{el.size}</div>
                  </div>
                </div>
                <div>
                  <hr />
                  <p className="model-title__bold">Характеристики производителя</p>
                </div>
                <div className={cls.ctnModel}>
                  <div className={cls.infoData}>
                    <p className={cls.title}>DOT:</p>
                    <div className={cls.data}>{el.dot}</div>
                  </div>
                  <div className={cls.infoData}>
                    <p className={cls.title}>Дата Производства:</p>
                    <div className={cls.data}>{moment(el?.date).format("DD.MM.YYYY")}</div>
                  </div>
                  <div className={cls.infoData}>
                    <p className={cls.title}>Глубина протектора:</p>
                    <div className={cls.data}>{el.tread_start}</div>
                  </div>
                  <div className={cls.infoData}>
                    <p className={cls.title}>Стоимость:</p>
                    <div className={cls.data}>{el.price}</div>
                  </div>
                </div>
                <div>
                  <hr />
                  <p className="model-title__bold">Начальные параметры для б/у шин</p>
                </div>
                {el.action === null ? (
                  <div className={cls.data}>Данные не найдены.</div>
                ) : (
                  <div className={cls.ctnModel}>
                    <div className={cls.infoData}>
                      <p className={cls.title}>Пробег:</p>
                      <div className={cls.data}>{el?.action?.run}</div>
                    </div>
                    <div className={cls.infoData}>
                      <p className={cls.title}>Глубина протектора:</p>
                      <div className={cls.data}>{el?.action?.tread}</div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={cls.ctnButton}>
          <button
            form="tyreOnTransport"
            type="submit"
            className={(cls.modalButton, "modal__button")}
            onClick={handleClose}
          >
            Отмена
          </button>
          <button form="tyreOnTransport" type="submit" className="modal__button">
            Установить
          </button>
        </div>
      </ModalWindow>
    </form>
  );
};

export default ModalInstalltionOnTransport;
