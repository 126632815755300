const Speed = () => (
  <svg width={32} height={28} fill="none">
    <path
      fill="#7E49B4"
      d="M16 8.4c-3.1 0-5.6 2.5-5.6 5.6 0 3.1 2.5 5.6 5.6 5.6 3.1 0 5.6-2.5 5.6-5.6 0-3.1-2.5-5.6-5.6-5.6Z"
    />
    <path
      fill="#7E49B4"
      d="M29 0H3C1.3 0 0 1.3 0 3v22c0 1.7 1.3 3 3 3h26c1.7 0 3-1.3 3-3V3c0-1.7-1.3-3-3-3ZM16 21.6c-4.2 0-7.6-3.4-7.6-7.6 0-4.2 3.4-7.6 7.6-7.6 4.2 0 7.6 3.4 7.6 7.6 0 4.2-3.4 7.6-7.6 7.6Z"
    />
    <path
      fill="#fff"
      d="M16 6.4c-4.2 0-7.6 3.4-7.6 7.6 0 4.2 3.4 7.6 7.6 7.6 4.2 0 7.6-3.4 7.6-7.6 0-4.2-3.4-7.6-7.6-7.6Zm0 13.2c-3.1 0-5.6-2.5-5.6-5.6 0-3.1 2.5-5.6 5.6-5.6 3.1 0 5.6 2.5 5.6 5.6 0 3.1-2.5 5.6-5.6 5.6Z"
    />
    <path fill="#fff" d="m19.814 11.054-2.675 3.678a1.457 1.457 0 0 1-2.443-.134" />
  </svg>
);
export default Speed;
