import { useEffect, useState } from "react";
import cls from "./style/TyrePositionList.module.scss";
import { models } from "helpers/constructorModels";
import { trailers } from "helpers/constructorTrailers";

const JournalListTyre = ({
  modelId,
  trailerId,
  handlePositions,
  defaultPositions,
  tyre,
  position_ending,
  position_starting,
}: {
  position_ending: number[];
  position_starting: number[];
  modelId: number;
  trailerId: null | number;
  wheelCount: number;
  handlePositions: (positions: number[]) => void;
  defaultPositions: number[];
  tyre: [] | [[number, number]];
}) => {
  const [tyrePositions, setTyrePositions] = useState<number[]>(defaultPositions);
  const [positionStart, setPositionStart] = useState<any>();

  const modelAxes = [...models[modelId].axes].reverse();
  const trailerAxes = trailerId !== null ? [...trailers[trailerId].axes].reverse() : [];
  const ending = position_starting ? position_starting[0] : null;

  useEffect(() => {
    setPositionStart(position_starting ? position_starting[0] : null);
  }, []);

  const isEditable = tyrePositions.length !== tyre?.length;

  useEffect(() => {
    handlePositions(tyrePositions);
  }, [tyrePositions]);

  const getWheelByIndex = (index: number) => {
    if (tyrePositions.indexOf(index) === -1) return null;
    return tyrePositions.indexOf(index) + 1;
  };

  let wheelIndex = 0;

  const axesListModel = modelAxes.map((axis, axisIndex) => {
    const wheels = axis.wheels.map((offset: any) => {
      wheelIndex++;
      const wIndex = wheelIndex;

      const content = getWheelByIndex(wIndex);

      const classes = ["wheel_list"];
      if (axis.mini) classes.push("wheel_list--mini");
      if (isEditable && !content) classes.push("wheel_list--editable");
      classes.push(!content ? "wheel_list--empty" : "wheel_list--filled");
      if (positionStart === wIndex) classes.push("wheel_list--filled_red");

      return (
        <div
          className="wheel-container_list"
          style={{ transform: `translate(0, ${offset}px)` }}
          key={wIndex}
        >
          <div className={classes.join(" ")}>
            {/* [{wIndex}] */}
            {/* {!!content && content} */}
            {!content && <span className="hidden-index">{tyrePositions.length + 1}</span>}
          </div>
        </div>
      );
    });

    return (
      <div
        className="axis_list"
        key={axisIndex}
        style={{ transform: `translate(${axis.offset}px, 0)` }}
      >
        {wheels}
      </div>
    );
  });

  const axesListTrailer = trailerAxes.map((axis, axisIndex) => {
    const wheels = axis.wheels.map((offset) => {
      wheelIndex++;
      const wIndex = wheelIndex;
      const content = getWheelByIndex(wIndex);

      const classes = ["wheel_list"];
      if (axis.mini) classes.push("wheel_list--mini");
      if (isEditable && !content) classes.push("wheel_list--editable");
      classes.push(!content ? "wheel_list--empty" : "wheel_list--filled");

      return (
        <div
          className="wheel-container_list"
          style={{ transform: `translate(0, ${offset}px)` }}
          key={wIndex}
        >
          <div className={classes.join(" ")}>
            {/* [{wIndex}] */}
            {/* {!!content && content} */}
            {!content && <span className="hidden-index">{tyrePositions.length + 1}</span>}
          </div>
        </div>
      );
    });

    return (
      <div className="axis" key={axisIndex} style={{ transform: `translate(${axis.offset}px, 0)` }}>
        {wheels}
      </div>
    );
  });

  return (
    <div className="view-model_list__container">
      <div
        className="view-model_list"
        style={{
          padding: `${models[modelId].globalPadding}px 0`,
        }}
      >
        <div style={{ transform: "scale(0.8)" }}>{models[modelId].flat}</div>
        {tyrePositions.length > 0 ? (
          <div className="wheels_list" style={{ pointerEvents: "none", transform: "scale(0.8)" }}>
            {axesListModel}
          </div>
        ) : (
          <div className="wheels_list">{axesListModel}</div>
        )}
      </div>

      {trailerId && (
        <div
          className="view-model_list view-model--trailer"
          style={{
            padding: `${trailers[trailerId].globalPadding}px 0`,
          }}
        >
          <div style={{ transform: "scale(0.8)" }}>{trailers[trailerId].flat}</div>
          {tyrePositions.length > 0 ? (
            <div
              className="wheel"
              style={{ position: "absolute", pointerEvents: "none", transform: "scale(0.8)" }}
            >
              {axesListTrailer}
            </div>
          ) : (
            <div className="wheel" style={{ position: "absolute" }}>
              {axesListTrailer}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default JournalListTyre;
