import Select, { components } from "react-select";

const SelectInput = ({
  options = [],
  value = 0,
  className,
  onChange,
  placeholder,
}: {
  options: any[];
  className?: string;
  value?: any;
  onChange: (value: string) => void;
  placeholder?: string;
}) => {
  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className="react-select__dropdown"></div>
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      boxShadow: "none",
      borderColor: "#C5CBD9",
      "&:hover": { borderColor: "#C5CBD9" },
    }),
    option: (base: any, state: any) => ({
      ...base,
      color: "#2F4267",
      fontWeight: state.isSelected ? "700" : "400",
      backgroundColor: "#fff",
    }),
  };

  const selectedValue = options?.filter((item) => item.value == value);

  return (
    <Select
      isSearchable={false}
      isClearable={false}
      value={selectedValue ? selectedValue[0] : options[0]}
      classNamePrefix="react-select"
      className={className}
      options={options}
      components={{ DropdownIndicator }}
      styles={customStyles}
      onChange={(item) => onChange(item.value)}
      placeholder={placeholder}
    />
  );
};

export default SelectInput;
