import { ModalsState } from "redux/modals/modals_types";
import ModalWindow from "./ModalWindow";
import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useAction";
import cls from "./style/StyleModal.module.scss";
import { useEffect, useRef, useState } from "react";
import ModalInput from "components/ui/Inputs/ModalInput";
import ModalTextarea from "components/ui/Textarea/ModalTextarea";
import CustomDatePicker from "components/datepicker/CustomDatePicker";
import moment from "moment";
import imgFile from "../../assets/images/UploadFileIcon/Vector.svg";
import delNoActiv from "../../assets/images/UploadFileIcon/delteNoAction.svg";
import toast from "react-hot-toast";
import ValidationDelete from "./modal-components/UploadFile/ValidationDelete";
import truncateFilename from "./modal-components/funcFileName";

const ModalRepairTyre: React.FC = () => {
  const filePicker = useRef<any>(null);
  const { addAction } = useActions();
  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const { skladTyre, id_tyre_moving } = useTypedSelector<any>((state) => state.tyres);
  const { detail_repair } = useTypedSelector((state) => state.actions);
  const [infoTyreRepair, setInfoTyreRepair] = useState<any>();
  const [production_date, setProdDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectFile, setSelectFile] = useState<any>([]);
  const [delValidation, setDelValidation] = useState<any>(null);
  const { toggleModal } = useActions();
  const [form, setForm] = useState<any>({
    tyre_id: "",
    type_of_repair: "",
    price: "",
    date: production_date,
    reason: "",
    tread: 0,
    run: 0,
    comment: "",
    uploaded_file: "",
  });
  const dataTread = infoTyreRepair?.map((el: any) => el?.action?.tread)[0];
  const dataRun = infoTyreRepair?.map((el: any) => el?.action?.run)[0];

  const handleClose = () => {
    setForm({
      tyre_id: "",
      type_of_repair: "",
      price: "",
      date: production_date,
      reason: "",
      tread: 0,
      run: 0,
      comment: "",
      uploaded_file: "",
    });
    toggleModal({ toggle: false, modal: "modal_repair_tyre" });
    setSelectFile([]);
  };

  useEffect(() => {
    if (skladTyre?.length === 0) {
      setInfoTyreRepair(detail_repair);
    } else {
      setInfoTyreRepair(skladTyre?.filter((el: any) => el.id === id_tyre_moving));
    }
  }, [id_tyre_moving]);

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      tread: dataTread,
      run: dataRun,
      type: "repair",
      tyre_id: id_tyre_moving,
      date: production_date,
      [name]: value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    selectFile.forEach((file: any) => {
      formData.append("uploaded_file[]", file);
    });
    formData.append("tread", dataTread);
    formData.append("run", dataRun);
    formData.append("type_of_repair", form.type_of_repair);
    formData.append("price", form.price);
    formData.append("date", production_date);
    formData.append("reason", form.reason);
    formData.append("type", "repair");
    formData.append("comment", form.comment);
    formData.append("tyre_id", id_tyre_moving);
    addAction(formData, "repair");
    toast.success("Данные успешно добавлены");
    handleClose();
  };

  const onDateChoose = (from: string) => {
    setProdDate(from);
  };

  const handleChangeUpload = (e: any) => {
    setSelectFile([...selectFile, e.target.files[0]]);
  };
  const clickDelete = (e: any) => {
    setDelValidation(e);
  };
  const closeValidDelete = () => {
    setDelValidation(null);
  };
  const handleDelete = (e: any) => {
    setSelectFile(selectFile?.filter((_: any, i: any) => i !== e));
    setDelValidation(null);
  };
  const handlePick = (e: any) => {
    e.preventDefault();
    setDelValidation(null);
    filePicker.current.click();
  };
  return (
    <ModalWindow
      isShow={modals.modal_repair_tyre}
      title="Ремонт шин"
      dialogClassName="modal_box-big"
      handleClose={handleClose}
    >
      <div>
        <p className="model-title__bold">Информация о шине</p>
      </div>
      {infoTyreRepair &&
        infoTyreRepair.map((el: any) => (
          <div key={el.id}>
            <div className={cls.ctnInfoTyres}>
              <div className={cls.infoData}>
                <p className={cls.title}>Бренд:</p>
                <div className={cls.data}>{el.brand}</div>
              </div>
              <div className={cls.infoData}>
                <p className={cls.title}>Размер:</p>
                <div>{el.size}</div>
              </div>
              <div className={cls.infoData}>
                <p className={cls.title}>Пробег:</p>
                <div>{el.action?.run}</div>
              </div>
              <div className={cls.infoData}>
                <p className={cls.title}>Глубина протектора:</p>
                <div>{el.tread_start}</div>
              </div>
            </div>
            <div>
              <hr />
              <p className="model-title__bold">Ремонт</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal__inputs-box">
                <ModalInput
                  titleClass="modal-password_title"
                  title="Вид ремонта"
                  // length={3}
                  name="type_of_repair"
                  value={form.type_of_repair}
                  onChange={handleInput}
                />
                <ModalInput
                  titleClass="modal-password_title"
                  title="Стоимость"
                  type="number"
                  // length={3}
                  name="price"
                  value={form.price}
                  onChange={handleInput}
                />
              </div>
              <div className="modal__inputs-box">
                <div className="input-area input-area--border w100 m0">
                  <label className="label">Дата производства</label>
                  <CustomDatePicker
                    onChange={onDateChoose}
                    date={production_date}
                    classes="react-datepicker_big"
                  />
                </div>
                <ModalInput
                  titleClass="modal-password_title"
                  title="Причина ремонта"
                  // length={3}
                  name="reason"
                  value={form.reason}
                  onChange={handleInput}
                />
              </div>
              <ModalTextarea
                titleClass="modal-password_title"
                title="Комментарий (120 символов)"
                length={64}
                rowsLength={2}
                name="comment"
                value={form.comment}
                onChange={handleInput}
              />
              <div>
                <p className="model-title__bold">Прикрепленные файлы</p>
              </div>
              <div>
                <div className={cls.ctnButtonFile}>
                  <button className="modal__button" onClick={handlePick}>
                    Прикрепить файл
                  </button>
                  <input
                    id="myFileInput"
                    type="file"
                    ref={filePicker}
                    multiple
                    onChange={handleChangeUpload}
                    className={cls.input}
                  />
                </div>
                <div className={cls.tableFile}>
                  {selectFile?.map((el: any, index: any) => (
                    <div className={cls.ctnFile} key={index}>
                      <div className={cls.infoFile}>
                        <img className={cls.imgFile} src={imgFile} alt="file" />
                        <div className={cls.fileName}>{truncateFilename(el.name)}</div>
                      </div>
                      {delValidation === index ? (
                        <div className={cls.modalValid}>
                          <ValidationDelete
                            onClickYes={() => handleDelete(index)}
                            onClickNo={closeValidDelete}
                          />
                        </div>
                      ) : (
                        <div />
                      )}
                      <div onClick={() => clickDelete(index)} className={cls.basket}>
                        <img src={delNoActiv} alt="basket" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={cls.ctnButton}>
                <button
                  form="tyreOnTransport"
                  type="submit"
                  className={(cls.modalButton, "modal__button")}
                  onClick={handleClose}
                >
                  Отмена
                </button>
                <button type="submit" className="modal__button">
                  Сохранить
                </button>
              </div>
            </form>
          </div>
        ))}
    </ModalWindow>
  );
};

export default ModalRepairTyre;
