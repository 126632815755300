import { useCallback } from "react";

export default function BalloonHandler({ myMap }: any) {
  const centerMapOnCoordinates = useCallback(
    (coordinates: any) => {
      if (!myMap || !coordinates) return;

      try {
        myMap.setCenter(coordinates, 16, { checkZoomRange: true }).catch((error: any) => {
          console.error("Ошибка при центрировании карты:", error);
        });
      } catch (error) {
        console.error("Ошибка в centerMapOnCoordinates:", error);
      }
    },
    [myMap]
  );

  return { centerMapOnCoordinates };
}
