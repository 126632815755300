const Temp = () => (
  <svg width={32} height={28} fill="none">
    <path
      fill="#F18101"
      d="M29 0H3C1.3 0 0 1.3 0 3v22c0 1.7 1.3 3 3 3h26c1.7 0 3-1.3 3-3V3c0-1.7-1.3-3-3-3ZM16.7 20c-2.1 0-3.7-1.7-3.7-4 0-1.5.7-2.8 1.9-3.4V9c0-1.2.7-2 1.9-2 1 0 1.9.9 1.9 2v3.5c1 .7 1.9 2 1.9 3.5-.2 2.3-1.8 4-3.9 4Z"
    />
    <path
      fill="#F18101"
      d="M17.2 14.5V9h-1v5.5c-.5.1-1 .8-1 1.5 0 .9.6 1.6 1.5 1.6.7 0 1.5-.7 1.5-1.6 0-.7-.4-1.2-1-1.5Z"
    />
    <path
      fill="#fff"
      d="M18.6 12.5V9c0-1.1-.9-2-1.9-2-1.1 0-1.9.8-1.9 2v3.6c-1.1.6-1.8 1.9-1.8 3.4 0 2.3 1.6 4 3.7 4s3.7-1.7 3.7-4c0-1.5-.8-2.8-1.8-3.5Zm-1.9 5.1c-.9 0-1.5-.7-1.5-1.6 0-.7.5-1.3 1-1.5V9h1v5.5c.6.3 1 .8 1 1.5 0 .9-.7 1.6-1.5 1.6Z"
    />
  </svg>
);
export default Temp;
