import { useTypedSelector } from "hooks/useTypedSelector";
import { UserState } from "redux/user/user_types";
import { LinkMe } from "./Header.styled";
import { useContext, useRef, useState } from "react";
import { ReactComponent as Cross } from "assets/images/cross.svg";
import useClickOutside from "hooks/useClickOutside";
import { MobileContext } from "context/MobileContext";
import Account from "./Account/Account";
import LineExit from "./LineExit/LineExit";
const HeaderNavs = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menu = useRef<HTMLHeadingElement>(null);
  useClickOutside(menu, () => (isOpen ? setIsOpen(false) : {}));

  return (
    <div className="header-navs">
      {/* <button role="menu" className="header-navs__toggle" onClick={() => setIsOpen(true)}>
        Меню
      </button> */}
      <div ref={menu} className={`header-navs__menu${isOpen ? " header-navs__menu--active" : ""}`}>
        <Account />
        {/* <LinkMe
          to="/profile"
          title="Перейти в настройки пользователя"
          onClick={() => handlerOpen()}
        >
          {user?.data?.name}
        </LinkMe> */}
        <LineExit />
        <button role="banner" className="header-navs__close" onClick={() => setIsOpen(false)}>
          <Cross className="logo_exit" />
        </button>
      </div>
    </div>
  );
};

export default HeaderNavs;
