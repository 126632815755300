const PressureMedium = () => (
  <svg width={32} height={28} fill="none">
    <path
      fill="#F2A6A6"
      fillRule="evenodd"
      d="M3 0a3 3 0 0 0-3 3v22a3 3 0 0 0 3 3h26a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3Zm10.526 20.056h1.525v-.563h1.898v.563h1.525v-.563h1.294v.563h1.525v-1.054a.547.547 0 0 1 .003-.047l.001-.005a7.783 7.783 0 0 0 1.538-3.409c.185-.908.233-1.872.046-2.793-.236-1.21-.8-2.225-1.59-3.135l-.508-.523a.144.144 0 0 1-.022-.039.163.163 0 0 1-.014-.06V8h-1.525v1.413c0 .174.057.347.22.442.65.538 1.13 1.213 1.527 2.032l.002.003c.48.943.612 1.979.436 3.067v.002a6.583 6.583 0 0 1-.884 2.412l-.005.008-.003.008a1.76 1.76 0 0 1-.217.316v.001c-.038.048-.08.1-.121.157l-.012.012-.005.005a.11.11 0 0 1-.01.008h-8.3l-.01-.008a.47.47 0 0 1-.017-.017c-.041-.056-.083-.109-.12-.157h-.001a1.76 1.76 0 0 1-.216-.317l-.004-.009-.005-.007a6.583 6.583 0 0 1-.883-2.412v-.002c-.177-1.088-.044-2.123.435-3.067l.002-.003c.397-.82.878-1.494 1.526-2.032.164-.095.221-.268.221-.442V8h-1.526v.991a.163.163 0 0 1-.013.06.144.144 0 0 1-.023.039l-.5.515-.007.008c-.79.91-1.354 1.924-1.59 3.135-.187.921-.14 1.885.046 2.793a7.783 7.783 0 0 0 1.538 3.41v.004a.247.247 0 0 1 .003.029v1.072h1.527v-.563H13.526v.563Zm1.727-5.91h1.526V9.68h-1.526v4.465Zm1.526.829h-1.526v1.56h1.526v-1.56Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M15.051 20.056h-1.525v-.563h-1.294v.563h-1.525v-1.072a.247.247 0 0 0-.003-.029l-.001-.005a7.783 7.783 0 0 1-1.538-3.409c-.186-.908-.233-1.872-.045-2.793.235-1.21.799-2.224 1.59-3.135l.006-.008.5-.515a.144.144 0 0 0 .023-.039.163.163 0 0 0 .013-.06V8h1.526v1.413c0 .174-.057.347-.22.442-.65.538-1.13 1.213-1.527 2.032l-.002.003c-.479.944-.612 1.979-.436 3.067v.002c.133.864.442 1.683.884 2.412l.005.008.004.008c.052.107.122.198.216.316v.001c.038.048.08.1.121.157a.47.47 0 0 0 .027.025h8.3l.01-.008.005-.005.012-.012c.041-.056.083-.109.12-.157h.001a1.76 1.76 0 0 0 .217-.317l.003-.009.005-.007a6.583 6.583 0 0 0 .883-2.412v-.002c.177-1.088.044-2.124-.435-3.067l-.002-.003c-.397-.82-.878-1.494-1.526-2.032-.164-.095-.221-.268-.221-.442V8h1.525v.991c0 .016.005.038.014.06.01.023.02.036.023.039l.507.523c.79.91 1.354 1.924 1.59 3.135.188.921.14 1.885-.046 2.793a7.783 7.783 0 0 1-1.538 3.41v.004a.247.247 0 0 0-.004.047v1.054h-1.525v-.563h-1.294v.563H16.95v-.563H15.05v.563Z"
    />
    <path
      fill="#fff"
      d="M16.779 14.145h-1.526V9.68h1.526v4.465ZM15.253 14.975h1.526v1.56h-1.526v-1.56Z"
    />
  </svg>
);
export default PressureMedium;
