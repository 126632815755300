import { Dispatch } from "redux";
import { CarActions, CarActionTypes, ICar } from "./car_types";
import { MapActionTypes } from "../map/map_types";
import api from "../../api";
import { ModalsActions, ModalsActionTypes } from "../modals/modals_types";
import { getCars } from "../cars/cars_actions";
import { toggleModal } from "../modals/modals_actions";
import { SettingsActions, SettingsActionTypes } from "../settings/settings_types";
import {
  ConstructorActions,
  ConstructorActionTypes,
  IConstructor,
} from "../constructor/constructor_types";

export const getCar = (car_id: number) => {
  return async (dispatch: Dispatch<CarActions | SettingsActions | ConstructorActions>) => {
    try {
      const result: any = await api.getCar(car_id);

      dispatch({
        type: CarActionTypes.GET_CAR,
        payload: result.data.vehicle,
      });

      if (result.data.vehicle.last_limit) {
        dispatch({
          type: SettingsActionTypes.GET_LAST_LIMITS,
          payload: result.data.vehicle.last_limit,
        });
      }

      if (result.data.vehicle.design !== undefined) {
        if (result.data.vehicle.design === null) {
          dispatch({ type: ConstructorActionTypes.RESET_CONSTRUCTOR });
        } else {
          dispatch({
            type: ConstructorActionTypes.LOAD_CONSTRUCTOR,
            payload: {
              modelId: result.data.vehicle.design.model_id,
              positions: result.data.vehicle.design.positions,
              trailerId: result.data.vehicle.design.trailer_id,
            },
          });
        }
      }

      return result;
    } catch (e: any) {
      console.log("e", e.message);
      return e.response;
    }
  };
};

export const rememberCar = (vehicle: ICar) => {
  return async (dispatch: Dispatch<CarActions | SettingsActions | ConstructorActions>) => {
    try {
      dispatch({
        type: CarActionTypes.GET_CAR,
        payload: vehicle,
      });

      if (vehicle.last_limit) {
        dispatch({
          type: SettingsActionTypes.GET_LAST_LIMITS,
          payload: vehicle.last_limit,
        });
      }

      if (vehicle.design) {
        dispatch({
          type: ConstructorActionTypes.LOAD_CONSTRUCTOR,
          payload: {
            modelId: vehicle.design.model_id,
            positions: vehicle.design.positions,
            trailerId: vehicle.design.trailer_id,
          },
        });
      }
    } catch (e: any) {
      console.log("e", e.message);
    }
  };
};

export const changeCarConstructor =
  (payload: IConstructor) => async (dispatch: Dispatch<CarActions>) => {
    dispatch({ type: CarActionTypes.CAR_CHANGE_CONSTRUCTOR, payload });
  };

export const addCar = (data: any) => async (dispatch: Dispatch<CarActions | any>) => {
  await api.addCar(data);
  dispatch(getCars());
};

export const getCarExtendedInfo = (id: number) => {
  return async (dispatch: Dispatch<CarActions | any>) => {
    try {
      dispatch(loadingCarExtendedInfo(true));
      const response: any = await api.getCar(id);

      dispatch({
        type: CarActionTypes.GET_CAR_EXTENDED,
        payload: response.data.vehicle,
      });

      return response;
    } catch (e: any) {
      console.log("e", e.message);
    }
  };
};

export const resetCarExtendedInfo = () => {
  return async (dispatch: Dispatch<CarActions | any>) => {
    try {
      dispatch({
        type: CarActionTypes.RESET_CAR_EXTENDED,
      });
    } catch (e: any) {
      console.log("e", e.message);
    }
  };
};

export const resetCar = () => {
  return async (dispatch: Dispatch<CarActions>) => {
    dispatch({ type: CarActionTypes.RESET_CAR });
  };
};

export const loadingCarExtendedInfo = (toggle: boolean) => {
  return async (dispatch: Dispatch<CarActions>) => {
    dispatch({
      type: CarActionTypes.LOADING_EXTENDED,
      payload: toggle,
    });
  };
};

export const putCarExtendedInfo = (id: number, car: ICar) => {
  return async (dispatch: Dispatch<CarActions | ModalsActions | any>) => {
    console.log("action");

    // try {
    const result = await api.putCar(id, car);
    dispatch(getCars());
    dispatch(getCar(id));
    dispatch({
      type: ModalsActionTypes.TOGGLE_MODAL,
      payload: { toggle: false, modal: "modal_car_extended_info" },
    });
    return result;
    // } catch (e: any) {
    //   console.log("e", e.message);
    //   return e.response;
    // }
  };
};

export const ChangeInputCarExtendedInfo = (key: string, value: string) => {
  return async (dispatch: Dispatch<CarActions>) => {
    dispatch({
      type: CarActionTypes.CHANGE_INPUT_CAR_EXTENDED,
      payload: { key, value },
    });
  };
};

export const getLastPointServer =
  (device_id: number, show: boolean) => async (dispatch: Dispatch<CarActions | any>) => {
    const result: any = await api.getLastPoint(device_id);
    dispatch({
      type: CarActionTypes.GET_LAST_POINT,
      payload: result.data.point,
    });

    if (show)
      dispatch({
        type: MapActionTypes.CHANGE_POINT,
        payload: result.data.point,
      });

    return result;
  };

export const deleteCar = (id: number) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const result = await api.deleteCar(id);
      // await dispatch(getFolders());
      await dispatch(getCars());
      dispatch(toggleModal({ toggle: false, modal: "modal_car_extended_info" }));
      return result;
    } catch (e: any) {
      console.log("error", e);
      // dispatch({ type: GroupActionsTypes.GROUP_ERROR, payload: 'ошибка' });
      return e.response;
    }
  };
};

export const carUpdateLastPointAddress = (payload: string) => {
  return async (dispatch: Dispatch<CarActions>) => {
    dispatch({ type: CarActionTypes.CAR_UPDATE_LAST_POINT_ADDRESS, payload });
  };
};

export const getCalendar = (id: number, from: string) => {
  return async (dispatch: Dispatch<CarActions>) => {
    const response: any = await api.getCalendar(id, from);
    dispatch({
      type: CarActionTypes.GET_CALENDAR,
      payload: response,
    });
  };
};

export const getIdCars = (value: any) => {
  return {
    type: CarActionTypes.GET_ID_CAR_STORE,
    payload: value,
  };
};

// export const carTyrePosition = (carId: any, position: any) => {
//   return {
//     type: CarActionTypes.CAR_TYRE_POSITION,
//     payload: { car: carId, position: [...position] },
//   };
// };

export const carTyrePosition = (carId: number, newPositions: number[]) => {
  return (dispatch: any) => {
    // Диспатчим обновленные позиции в Redux
    dispatch({
      type: CarActionTypes.CAR_TYRE_POSITION,
      payload: { carId, positions: newPositions },
    });
  };
};
