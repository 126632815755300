import T_1_AXIS_OPEN from "../assets/images/constructor/trailers/1_axis_trailer.svg";
import T_2_AXIS_OPEN from "../assets/images/constructor/trailers/2_axis_trailer.svg";
import T_3_AXIS_OPEN from "../assets/images/constructor/trailers/3_axis_trailer.svg";
import T_1_AXIS_CLOSE from "../assets/images/constructor/trailers/close_trailer_1_axis.svg";
import T_2_AXIS_CLOSE from "../assets/images/constructor/trailers/close_trailer_2_axis.svg";
import T_3_AXIS_CLOSE from "../assets/images/constructor/trailers/close_trailer_3_axis.svg";
import T_SEEDERS from "../assets/images/constructor/trailers/прицеп к сеялке.svg";

export const trailer_category = [
  { id: 1, type: 1, title: "Прицеп с 1 осью (открытый)", images: T_1_AXIS_OPEN },
  { id: 2, type: 1, title: "Прицеп с 2 осями (открытый)", images: T_2_AXIS_OPEN },
  { id: 3, type: 1, title: "Прицеп с 3 осями (открытый)", images: T_3_AXIS_OPEN },
  { id: 4, type: 2, title: "Прицеп с 1 осями (закрытый)", images: T_1_AXIS_CLOSE },
  { id: 5, type: 2, title: "Прицеп с 2 осями (закрытый)", images: T_2_AXIS_CLOSE },
  { id: 6, type: 2, title: "Прицеп с 3 осями (закрытый)", images: T_3_AXIS_CLOSE },
  { id: 7, type: 3, title: "Сеялка", images: T_SEEDERS },
];
