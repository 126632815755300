const reasonList = [
  "",
  "Повреждения протектора",
  "Повреждения боковины",
  "Разрушения брекера",
  "Повреждения каркаса",
  "Повреждения борта",
];
const defect_tread_damage_list = [
  "Сквозной пробой протектора с разрушением слоёв брекера и разрывом каркаса",
  "Сквозной пробой по протектору",
  "Полный износ рисунка протектора",
  "Усиленный износ рисунка протектора по краям беговой дорожки",
  "Усиленный износ рисунка протектора по центру беговой дорожки",
  "Односторонний износ",
  "Местный износ протектора (пятнистый износ)",
  "Выкрошивание резины рисунка протектора",
  "Продольные (круговые) разрезы, порезы по протектору",
  "Пилообразный износ рисунка протектора",
  "Разрушение резины протектора",
  "Отслоение протектора",
  "Местное отслоение протектора ошипованной шины",
  "Трещина по дну канавки",
  "Некачественный ремонт",
  "Параллельные слои брекера (или отсутствие детали брекера)",
  "Просвечивание и выход наружу нитей металлокорда брекера",
];
const defect_sidewall_damage_list = [
  "Сквозное механическое повреждение в плечевой зоне",
  "Механическое повреждение (пробой) боковины",
  "Круговое механическое повреждение боковины",
  "Вздутие по боковине в процессе эксплуатации",
  "Разрыв резины боковины по месту вздутия",
  "Отслоение резины боковины",
  "Мелкая сетка трещин старения",
  "Наплывы резины по боковине",
  "Пузыри в резине боковины",
  "Вздутие по боковине при монтаже",
  "Постороннее включение по боковине",
  "Дефект от застревания камней между сдвоенными шинами на задней оси автотранспортного средства",
  "Отслоение резины боковины от нитей корда каркаса с лучевыми разрывами резины боковины",
];
const defect_frame_damage_list = [
  "Разрушение брекера с изменением конфигурации профиля покрышки",
  "Расслоение в плечевой зоне — расслоение по кромке брекера",
  "Расслоение между слоями брекера",
];

const defect_side_damage_list = [
  "Расслоение в каркасе вследствие повреждения посторонним предметом",
  "Излом каркаса",
  "Расхождение стыка каркаса с просвечиванием нитей корда",
  "Выпадение нитей первого слоя каркаса",
];
const defect_brace_damage_list = [
  "Разрушение шины вследствие перегрева (подвулканизованный борт)",
  "Разрыв бортового кольца и боковины",
  "Повреждение надбортовой зоны шины",
  "Расслоение в надбортовой зоне",
  "Расслоение в надбортовой зоне по месту наплыва резины боковины",
  "Деформация бортового кольца",
  "Перетирание материалов бортовой зоны шины в месте соприкосновения с закраиной реборды обода",
];
export const generateObjectArray = (stringArray) => {
  return stringArray.map((item, index) => ({
    id: index + 1,
    label: item,
    value: item,
  }));
};
export const reason = generateObjectArray(reasonList);
export const defect_tread_damage = generateObjectArray(defect_tread_damage_list);
export const defect_sidewall_damage = generateObjectArray(defect_sidewall_damage_list);
export const defect_frame_damage = generateObjectArray(defect_frame_damage_list);
export const defect_side_damage = generateObjectArray(defect_side_damage_list);
export const defect_brace_damage = generateObjectArray(defect_brace_damage_list);
