import TyreDate from "components/tyres/TyreDate";
import TyreHeader from "components/tyres/TyreHeader";
import cls from "../style/Style.module.scss";
import imgMovingCar from "../../../assets/images/Group 1070.svg";
import imgBurger from "../../../assets/images/Group.svg";
import moment from "moment";
import { useActions } from "hooks/useAction";
import { useEffect, useState } from "react";
import { TyreProps } from "redux/tyres/tyres_types";
import { useTypedSelector } from "hooks/useTypedSelector";

const TyreList = ({ from, to, titles, tyres }: TyreListProps) => {
  const [isVisibleToltip, setIsVisibleToltip] = useState<any>(null);
  const [isOpenBurger, setIsOpenBurger] = useState<any>(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const { getSklatTyre, toggleModal, getIdTyreMovingCar } = useActions();
  const { closeAction } = useTypedSelector((state: any) => state.actions);

  useEffect(() => {
    getSklatTyre(tyres);
  }, []);
  const handleRepairMoving = (e: any) => {
    getIdTyreMovingCar(e);
    toggleModal({ toggle: true, modal: "modal_repair_tyre" });
  };
  const handleUtilizationMoving = (e: any) => {
    getIdTyreMovingCar(e);
    toggleModal({ toggle: true, modal: "modal_utilization_tyre" });
  };
  const handlePoint = async (e: any) => {
    getIdTyreMovingCar(e);
    toggleModal({ toggle: true, modal: "modal_installTyre_onCar" });
  };
  // const toogleMenu = (e: any) => {
  //   const buttonRect = e.currentTarget.getBoundingClientRect();
  //   setMenuPosition({
  //     top: buttonRect.bottom + window.scrollY,
  //     left: buttonRect.left + window.scrollX,
  //   });
  // };
  // const countPrice = (price: any, run: any) => {
  //   if (typeof price !== "number" || typeof run !== "number" || price <= 0 || run <= 0) {
  //     return "Невозможно рассчитать стоимость: нет данных о пробеге";
  //   }
  //   const countPrice = price / run;
  //   if (isNaN(countPrice)) {
  //     return "Невозможно рассчитать стоимость: результат не является числом";
  //   }
  //   return Math.trunc(countPrice);
  // };

  // console.log(tyres);

  return (
    <>
      <TyreDate from={from} to={to} />
      {tyres && tyres.length > 0 ? (
        <>
          <TyreHeader titles={titles} />
          {tyres.length > 0 &&
            tyres?.map((tyre) => (
              <div className="tyre_line tyre_table_box" key={tyre.id}>
                <div className="tyre_line_part bold">{`${tyre.model} ${tyre.brand}`}</div>
                <div className="tyre_line_part">
                  {tyre.last_move && tyre.last_move?.updated_at ? (
                    `${moment(tyre.last_move?.updated_at).format("YYYY-MM-DD")}/${moment(
                      tyre.action?.updated_at
                    ).format("YYYY-MM-DD")}`
                  ) : (
                    <div>не верная дата</div>
                  )}
                </div>
                <div className="tyre_line_part tyre_line_part--white-space">
                  {tyre.action.store?.reason}
                </div>
                <div className="tyre_line_part">
                  {tyre?.action && tyre?.action !== null ? <div>{tyre.action.run}</div> : 0}
                </div>
                <div className="tyre_line_part">{}</div>
                <div className={cls.ctnMoving}>
                  <div onClick={() => setIsOpenBurger(tyre.id)} className={cls.ctnBurger}>
                    <button className="report_time-more">
                      <img src={imgBurger} alt="burger" />
                    </button>
                    <div
                      onMouseLeave={() => setIsOpenBurger(null)}
                      className={`${cls.menuTyre} ${isOpenBurger === tyre.id ? cls.active : ""}`}
                    >
                      <div>
                        <p className={cls.titleMenu}>Меню Шины</p>
                      </div>
                      <div className={cls.textMenu}>
                        <button
                          className="vehicle-menu__button"
                          onClick={() => handleRepairMoving(tyre.id)}
                        >
                          Ремонт
                        </button>
                      </div>
                      <div className={cls.textMenu}>
                        <button
                          className="vehicle-menu__button"
                          onClick={() => handleUtilizationMoving(tyre.id)}
                        >
                          Утилизация
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    key={tyre.id}
                    onMouseEnter={() => setIsVisibleToltip(tyre.id)}
                    onMouseLeave={() => setIsVisibleToltip(null)}
                    className={cls.cntMovingCar}
                  >
                    <button className="report_time-more" onClick={() => handlePoint(tyre.id)}>
                      <img src={imgMovingCar} alt="carMoving" />
                    </button>
                    <div
                      className={`${cls.toltiptext} ${
                        isVisibleToltip === tyre.id ? cls.active : ""
                      }`}
                    >
                      Нажмите, чтобы установить шину на ТС
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </>
      ) : (
        <div className="border-text">Ничего не найдено</div>
      )}
    </>
  );
};

export default TyreList;

export interface TyreListProps {
  from: string;
  to?: string;
  titles: string[];
  tyres: TyreProps[];
}
