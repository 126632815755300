import { useTypedSelector } from "../hooks/useTypedSelector";
import { UserState } from "../redux/user/user_types";
import { ModalsState } from "../redux/modals/modals_types";
import { hasRole, TEXT_ROLES } from "../helpers";
import { useActions } from "../hooks/useAction";
import SelectInput from "../components/select-input/SelectInput";
import { useState } from "react";
import EmailConfirm from "../components/email-confirm/EmailConfirm";
import MobileSwitch from "components/mobile-toggler/MobileSwitch";

const ProfilePage = () => {
  const user: UserState = useTypedSelector((state) => state.user);
  const { toggleModalConfirm, toggleModal } = useActions();

  const [sendError, setSendError] = useState("");

  const { name, email, is_verified, profile, role } = user.data;

  const options: any = [];

  Object.keys(TEXT_ROLES).forEach((key) => {
    options.push({ value: key, label: TEXT_ROLES[key] });
  });

  const onChangeRole = (value: string) => {
    console.log("onChangeRole", value);
  };

  return (
    <div className="profile">
      <MobileSwitch />
      <div className="profile__container">
        <div className="profile__row">
          <h1 className="profile__title">
            Настройки <span className="profile__span">пользователя</span>
          </h1>
        </div>
        <div className="profile__row profile__border">
          <div className="profile__item company">
            <h6 className="company__title">Название компании</h6>

            {profile ? (
              <>
                <p className="company__name">{profile.title}</p>
                {hasRole(role, "moderator") && (
                  // <div>
                  //   <button>Изменить</button>
                  // </div>
                  <></>
                )}
              </>
            ) : (
              <p className="company__name">Отсутствует</p>
            )}
          </div>
          <div className="profile__item">
            <p className="company__role">
              Роль в системе: <span className="company__current-role"> {TEXT_ROLES[role]}</span>
            </p>

            {hasRole(role, "moderator") && (
              // <div>
              //   <button>Изменить</button>
              // </div>
              <></>
            )}

            {hasRole(role, "moderator") && (
              <div>
                <SelectInput onChange={onChangeRole} options={options} />
              </div>
            )}
          </div>
        </div>
        <div className="profile__row">
          <div className="profile__item profile_address">
            {profile && (
              <>
                <p className="company__address">{profile.full_title}</p>
                <div className="company__inn">
                  <span className="company__span">ИНН: {profile.inn}</span>
                  <span className="company__span">КПП: {profile.kpp}</span>
                  <span className="company__span">ОГРН: {profile.ogrn}</span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="profile__row profile__border">
          <div className="profile__item profile__item--long">
            <label htmlFor="inputFio" className="company__title">
              ФИО
            </label>
            <div className="profile__user">
              <input
                className="profile__input profile__input--long"
                type="text"
                id="inputFio"
                disabled
                value={name}
              />
              <button
                className="modal__button "
                onClick={() => toggleModal({ toggle: true, modal: "modal_edit_user_data" })}
              >
                Изменить
              </button>
            </div>
          </div>
          <div className="profile__item">
            <label htmlFor="inputEmail" className="company__title">
              Логин / email
            </label>
            <div className="profile__user">
              <input
                className="profile__input profile__input--none profile__input--medium"
                type="text"
                id="inputEmail"
                value={email}
                disabled
              />
              {is_verified ? (
                <div className="profile__isConfirm">email подтвержден</div>
              ) : (
                <EmailConfirm />
              )}
            </div>
            {!!sendError && <div className="input-error-message">{sendError}</div>}
          </div>
        </div>
        <div className="profile__row profile__row--center">
          <div className="profile__item  profile__item--full">
            <button
              className="modal__button modal__button-password modal__button--big"
              onClick={() => toggleModal({ toggle: true, modal: "modal_set_new_password" })}
            >
              Изменить пароль
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
