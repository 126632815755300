import { ICategory } from "./constructorTypes";

// КАТЕГОРИИ
import PREW_1 from "../assets/images/constructor/categories/PREW_1.png";
// import PREW_2 from "../assets/images/constructor/categories/PREW_2.png";
// import PREW_3 from "../assets/images/constructor/categories/PREW_3.png";
import PREW_4 from "../assets/images/constructor/categories/PREW_4.png";
import PREW_6 from "../assets/images/constructor/categories/PREW_6.png";
import PREW_7 from "../assets/images/constructor/categories/PREW_7.png";
import PREW_8 from "../assets/images/constructor/categories/PREW_8.png";
import PREW_9 from "../assets/images/constructor/categories/PREW_9.png";
import PREW_10 from "../assets/images/constructor/categories/PREW_10.png";
import PREW_11 from "../assets/images/constructor/categories/PREW_11.png";
import PREW_5 from "../assets/images/constructor/categories/Погрузчик.svg";

export const categories: ICategory[] = [
  { id: 4, title: "Грузовик", image: PREW_4 },
  { id: 7, title: "Трактор", image: PREW_7 },
  { id: 8, title: "Комбайн", image: PREW_8 },
  { id: 10, title: "Автобус", image: PREW_10 },
  { id: 1, title: "Самосвал", image: PREW_1 },
  { id: 5, title: "Погрузчик", image: PREW_5 },
  { id: 9, title: "Тягач", image: PREW_9 },
  { id: 6, title: "Харвестер", image: PREW_6 },
  { id: 11, title: "Форвардер", image: PREW_11 },
];
