import { STEPS } from "../../helpers/constructorTypes";
import { ConstructorState, ConstructorActionTypes, ConstructorActions } from "./constructor_types";

const initialState: ConstructorState = {
  step: STEPS.CATEGORIES,
  categoryId: 0,
  modelId: null,
  trailerId: null,
  positions: [],
  get_constructor_all: null,
};

const ConstructorReducer = (state = initialState, action: ConstructorActions) => {
  switch (action.type) {
    case ConstructorActionTypes.CHANGE_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case ConstructorActionTypes.CHANGE_CATEGORY:
      return {
        ...state,
        categoryId: action.payload,
      };
    case ConstructorActionTypes.CHANGE_MODEL:
      return {
        ...state,
        modelId: action.payload,
      };
    case ConstructorActionTypes.CHANGE_TRAILER:
      return {
        ...state,
        trailerId: action.payload,
      };
    case ConstructorActionTypes.CHANGE_POSITION:
      return {
        ...state,
        positions: action.payload,
      };
    case ConstructorActionTypes.LOAD_CONSTRUCTOR:
      return {
        ...state,
        ...action.payload,
      };
    case ConstructorActionTypes.RESET_CONSTRUCTOR:
      return { ...initialState };
    case ConstructorActionTypes.GET_CONSTRUCTOR:
      return {
        ...state,
        get_constructor: action.payload,
      };
    case ConstructorActionTypes.GET_CONSTRUCTOR_ALL:
      return {
        ...state,
        get_constructor_all: action.payload,
      };
    case ConstructorActionTypes.LOADING_CONSTRUCTOR:
      return {
        ...state,
        loading_constructor: action.payload,
      };

    default:
      return state;
  }
};

export default ConstructorReducer;
