import cls from "../style/Confirmation.module.scss";

const Confirmation = ({ onClickOk, onClickCancel }: { onClickOk: any; onClickCancel: any }) => {
  return (
    <div
      className="modal"
      style={{
        display: "flex",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      }}
    >
      <div
        className="modal-content"
        style={{
          width: "500px",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <p className="report_title">Вы уверены, что хотите поменять шины местами?</p>
        <div className={cls.ctnButton}>
          <button onClick={onClickOk} className="button button--mint button--with-label ">
            Да
          </button>
          <button onClick={onClickCancel} className="button button--mint button--with-label ">
            Нет
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
