import React, { useState } from "react";
import cls from "./style/Style.module.scss";
import imgLine from "../../../assets/images/Line4.2.svg";
import imgExit from "../../../assets/images/ExitImg.svg";
import { useActions } from "hooks/useAction";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/useAppDispatch";
import { GlobalApi } from "api/GlobalApi";

export default function LineExit() {
  const [visible, setVisible] = useState(null);
  const { logout } = useActions();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    logout();
    localStorage.removeItem("@center");
    navigation("/");
    dispatch(GlobalApi.util.resetApiState());
  };

  return (
    <div className={cls.ctnExit}>
      <div className={cls.ctnLine}>
        <img src={imgLine} alt="line" />
      </div>
      <div onClick={handleLogout}>
        <img
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(null)}
          className={cls.imgExit}
          src={imgExit}
          alt="exit"
        />
      </div>
      {visible && (
        <div className={cls.tolTip}>
          <div className={cls.text}>Выход</div>
        </div>
      )}
    </div>
  );
}
