import { useTypedSelector } from "hooks/useTypedSelector";
import { useEffect, useState } from "react";
import { ModalsState } from "redux/modals/modals_types";
import { TyresState } from "redux/tyres/tyres_types";
import ModalWindow from "./ModalWindow";
import { useActions } from "hooks/useAction";
import Checkbox from "components/ui/Checkbox/Checkbox";
import ModalInput from "components/ui/Inputs/ModalInput";
import cls from "./style/StyleModal.module.scss";
import moment from "moment";
import TyreEdit from "components/constructor/placementTyre/TyreEdit";
import { ConstructorState } from "redux/constructor/constructor_types";
import { CarState } from "redux/car/car_types";
import TyrePositionList from "components/constructor/placementTyre/TyrePositionList";
import api from "api";
import toast from "react-hot-toast";
import MovingTyreInTs from "components/constructor/placementTyre/MovingTyreInTs";
import { useParams } from "react-router-dom";

interface updateTyresType {
  used: boolean;
  model: string;
  brand: string;
  size: string;
  dot: string;
  date: string;
  tread: number;
  price: number;
  run: number;
}

const ModalUpdateTyre: React.FC = () => {
  const { vehicle_id, tyre_id } = useParams();
  const vehicleId = Number(vehicle_id);
  const tyreId = Number(tyre_id);
  const { tyre }: TyresState = useTypedSelector((state) => state.tyres);
  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const [used, setUsed] = useState<boolean>(false);
  const { car, position_tyres }: CarState = useTypedSelector((state) => state.car);
  const dataPositions = position_tyres.map((el: any) => el.positions)[0];
  const constructor: ConstructorState = useTypedSelector((state) => state.design);
  const { modelId, trailerId } = constructor;
  const [run, setRun] = useState<string>("");
  const [readonly, setReadonly] = useState<boolean>(true);
  const { toggleModal, updateValue, getCarTyres, getTyre } = useActions();
  const [formUpdateTyres, setFormUpdateTyres] = useState<any>({
    model: tyre?.model,
    brand: tyre?.brand,
    size: tyre?.size,
    dot: tyre?.dot,
    date: tyre?.updated_at,
    tread_start: tyre?.tread_start,
    tread_start_by: tyre?.last_action?.tread,
    price: tyre?.price,
    run: run,
  });

  useEffect(() => {
    setFormUpdateTyres(tyre);
  }, [tyre]);

  const [errors, setErrors] = useState<any>({
    brand: [],
    model: [],
    type: [],
    run: [],
    tread: [],
    comment: [],
    reason: [],
    to: [],
    runLast: [],
  });
  useEffect(() => {
    setFormUpdateTyres(tyre);
  }, [tyre]);

  useEffect(() => {
    if (vehicleId) {
      getCarTyres(vehicleId);
    }
  }, [vehicleId]);

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormUpdateTyres({
      ...formUpdateTyres,
      [name]: value,
    });
  };
  const handlePosition = () => {};
  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_update_tyre" });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      updateValue(formUpdateTyres);
      console.log(formUpdateTyres);
      if (formUpdateTyres?.id) {
        api.putTyre(formUpdateTyres?.id, {
          model: formUpdateTyres.model,
          brand: formUpdateTyres.brand,
          size: formUpdateTyres.size,
          dot: formUpdateTyres.dot,
          date: formUpdateTyres.updated_at,
          tread_start: formUpdateTyres.tread_start,
          tread_start_by: formUpdateTyres.tread,
          price: formUpdateTyres.price,
          run: run,
        });
        console.log(formUpdateTyres);
        toast.success("Шина обновлена");
        handleClose();
      }
    } catch (error) {
      console.log(setErrors(error));
    }
  };

  const handleChangeRun = (e: React.FormEvent<HTMLInputElement>) => {
    setErrors({
      ...errors,
      run: [],
    });
    setRun((e.target as HTMLInputElement).value);
    if (
      tyre &&
      tyre.last_action &&
      tyre?.last_action?.run > +(e.target as HTMLInputElement).value
    ) {
      setErrors({
        ...errors,
        run: "Пробег не может быть меньше предыдущего",
      });
      return;
    }
  };

  return (
    <div>
      <ModalWindow
        isShow={modals.modal_update_tyre}
        title="Редактировать информацию о шине"
        dialogClassName="modal_box-big"
        handleClose={handleClose}
      >
        <form id="addTransport" className="modal-content__box" onSubmit={handleSubmit}>
          <div>
            <p className="model-title__bold">Сменить позицию</p>
            {car?.design != null && modelId && car && car?.position_tyres ? (
              // <TyrePositionList
              //   modelId={modelId}
              //   defaultPositions={tyre.position_tyre}
              //   trailerId={trailerId}
              //   wheelCount={8}
              //   handlePositions={handlePosition}
              //   tyre={tyre.position_tyre}
              // />
              <MovingTyreInTs
                run={tyre?.last_action?.run}
                tread={tyre?.last_action?.tread}
                tyre_id={tyre?.id}
                car={vehicleId}
                modelId={modelId}
                defaultPositions={dataPositions || car?.position_tyres}
                trailerId={trailerId}
                wheelCount={8}
                handlePositions={handlePosition}
                tyres={dataPositions || car?.position_tyres}
              />
            ) : (
              <></>
            )}
          </div>
          <div>
            <p className="model-title__bold">Модель и размеры шин</p>
          </div>
          <div style={{ marginBottom: "5px" }}>
            <Checkbox
              onChange={() => setUsed((e: any) => !e)}
              checked={used}
              text="Б/У"
              titleClasses="modal-password_title"
            />
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Бренд"
              // length={7
              name="brand"
              value={formUpdateTyres?.brand}
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.brand}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Модель"
              min={1}
              // length={3}
              name="model"
              value={formUpdateTyres?.model}
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.model}
            />
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Размер"
              type="number"
              // length={3}
              name="size"
              value={formUpdateTyres?.size}
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.size}
            />
          </div>
          <div>
            <p className="model-title__bold">Характеристики производителя</p>
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Dot"
              type="number"
              // length={3}
              name="dot"
              value={formUpdateTyres?.dot}
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.dot}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Дата производства"
              // length={3}
              value={moment(formUpdateTyres?.date).format("DD.MM.YYYY")}
              name="date"
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.date}
            />
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Глубина протектора"
              type="number"
              // length={3}
              // placeholder={String(tyre?.last_move.tread)}
              value={formUpdateTyres?.tread_start}
              name="tread_start"
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.tread}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Стоимость"
              type="number"
              // length={3}
              // placeholder={`${String(tyre?.price)} ₽ `}
              value={formUpdateTyres?.price}
              name="price"
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleInput(e)}
              errors={errors.price}
            />
          </div>
          <div>
            <p className="model-title__bold">Начальные параметры для б/у шин</p>
          </div>
          <div className="modal__inputs-box">
            <div className={cls.ctnInfoTyres}>
              <div className={cls.infoData}>
                <p className={cls.title}>Пробег:</p>
                <div>{tyre?.last_action?.run}</div>
              </div>
              <div className={cls.infoData}>
                <p className={cls.title}>Глубина протектора:</p>
                <div>{tyre?.last_action?.tread}</div>
              </div>
            </div>
          </div>
          <div className="modal-edit__button-box">
            <button form="addTransport" type="submit" className="modal__button">
              Сохранить
            </button>
          </div>
        </form>
      </ModalWindow>
    </div>
  );
};

export default ModalUpdateTyre;
