import TyreTitle from "components/tyres/TyreTitle";
import { useTypedSelector } from "hooks/useTypedSelector";
import { CarsState } from "redux/cars/cars_types";
import { useGetFoldersQuery } from "redux/folder/FolderApi";
import imgTS from "../assets/images/ТС.svg";
import cls from "./style/TyresPageStart.module.scss";

const TyresPageStart = () => {
  const { loading }: CarsState = useTypedSelector((state) => state.cars);
  const { isLoading } = useGetFoldersQuery();

  return (
    <>
      {!loading && !isLoading && (
        <div className="report_head">
          <div className={cls.ctnTC}>
            <div className={cls.ctnImg}>
              <img src={imgTS} alt="TC" />
            </div>
            <div className={cls.cntText}>Выберите TC</div>
          </div>
        </div>
      )}
    </>
  );
};

export default TyresPageStart;
