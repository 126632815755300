import Car from "./car/Car";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { like } from "../../helpers";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import { Vehicles } from "./Cars.styled";
import CarTyre from "./car/CarTyre";
import { CarState } from "redux/car/car_types";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useActions } from "hooks/useAction";

const Cars = ({ tyre = false }: { tyre?: boolean }) => {
  const cars = useTypedSelector((state) => state.cars);

  if (cars.loading) return <SkeletonLoading height="100vh" />;

  const filteredCars = cars.cars
    .filter(
      (car) => !cars.filter || like(car.car_number, cars.filter) || like(car.title, cars.filter)
    )
    .filter((car) => car.folder_id === 0);

  if (!filteredCars.length && !cars.filter && !cars.cars.length)
    return (
      <Vehicles>
        <div className="message-warning message-warning_car">Список пуст</div>
      </Vehicles>
    );

  if (!filteredCars.length && cars.filter)
    return (
      <Vehicles>
        <div className="message-warning message-warning_car">Ничего не найдено</div>
      </Vehicles>
    );

  return (
    <Vehicles>
      {filteredCars.map((car) =>
        !tyre ? <Car key={car.id} {...car} /> : <CarTyre key={car.id} {...car} />
      )}
    </Vehicles>
  );
};

export default Cars;
