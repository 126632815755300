import { useEffect, useState } from "react";
import { useActions } from "hooks/useAction";
import { TyresState } from "redux/tyres/tyres_types";
import { useTypedSelector } from "hooks/useTypedSelector";
import Loader from "components/loader/Loader";
import TyreTitle from "components/tyres/TyreTitle";
import TyreInfo from "components/tyres/TyreInfo";
import moment from "moment";
import TyreListUtilization from "./components/TyreListUtilization";

const TyresUtilizationPage = () => {
  const titles = [
    "ШИНА",
    "ДАТА УСТАНОВКИ / УТИЛИЗАЦИИ",
    "ПРИЧИНА УТИЛИЗАЦИИ",
    "ПРОБЕГ",
    "СТОИМОСТЬ (1 КМ ПРБЕГА)",
    "",
  ];

  const { postTyresByPeriod } = useActions();

  const { tyres_by_period, loading_tyres }: TyresState = useTypedSelector((state) => state.tyres);

  const [count, setCount] = useState<number>(0);
  const [countTyres, setCountTyres] = useState<any>(0);
  const today = moment(new Date()).format("YYYY-MM-DD");

  const init = async () => {
    await postTyresByPeriod(today, today, "utilization");
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    let sum = 0;
    if (tyres_by_period) {
      for (let index = 0; index < tyres_by_period.tyres.length; index++) {
        sum = sum + tyres_by_period.tyres[index].action?.run;
      }
    }
    setCount(sum);
    setCountTyres(tyres_by_period?.tyres.length);
  }, [tyres_by_period?.tyres]);

  const handlClick = () => {
    console.log("click");
  };
  return (
    <div>
      <div className="report_head">
        {loading_tyres ? (
          <Loader />
        ) : (
          <>
            <TyreTitle title="Утилизация" subtitle="шин" />
            <TyreInfo
              firstCount={{
                count: countTyres,
                title1: "количество",
                title2: "шин",
              }}
              secondCount={{ count: count, title1: "общий", title2: "пробег" }}
              showConstructor={false}
            />
            <TyreTitle title="Утилизированные " subtitle="шины" />
            <TyreListUtilization
              from={
                tyres_by_period && tyres_by_period.from
                  ? tyres_by_period.from
                  : moment(new Date()).format("YYYY-MM-DD")
              }
              to={tyres_by_period?.to}
              titles={titles}
              tyres={tyres_by_period?.tyres ? tyres_by_period?.tyres : []}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TyresUtilizationPage;
