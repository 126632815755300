import api from "api";
import { Dispatch } from "react";
import { ActionActionTypes, ActionsActions } from "./actions_types";
import { getTyre } from "redux/tyres/tyres_actions";

export const addActionAdding = (data: any) => async () => {
  await api.addActionAdding(data);
};

export const getActionsPeriod = (
  from: string,
  to: string,
  type: string | undefined = undefined
) => {
  return async (dispatch: Dispatch<ActionsActions>) => {
    try {
      dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: true });
      const response = await api.getActionsPeriod(from, to, type);

      dispatch({
        type: ActionActionTypes.GET_ACTIONS,
        payload: response,
      });
      dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: false });
    } catch (e: any) {
      return e;
    }
  };
};

export const changeActionFilter = (payload: { [key: string]: string }) => {
  return (dispatch: Dispatch<ActionsActions>) => {
    dispatch({ type: ActionActionTypes.ACTIONS_FILTER, payload });
  };
};
// export const periodTyres = (period: any) => {
//   return {
//     type: ActionActionTypes.CHOOSING_PERIOD,
//     payload: period,
//   };
// };

export const postActionsTyreByPeriod = (from: string, to: string, id: number) => {
  return async (dispatch: Dispatch<ActionsActions>) => {
    try {
      dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: true });
      const response = await api.postActionsTyreByPeriod(from, to, id);

      dispatch({
        type: ActionActionTypes.GET_ACTIONS,
        payload: response,
      });

      dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: false });
    } catch (e: any) {
      return e;
    }
  };
};

export const addAction =
  (data: any, type: string) => async (dispatch: Dispatch<ActionsActions | any>) => {
    await api.postAction(data, type);
    // dispatch(getTyre(data.tyre_id));
  };

export const getAxelActionsAll = () => {
  return async (dispatch: Dispatch<ActionsActions>) => {
    try {
      dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: true });
      const response = await api.getAxelActionsAll();
      dispatch({
        type: ActionActionTypes.GET_AXEL_ACTION_ALL,
        payload: response,
      });
      dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: false });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getActionsByVehicle = (vehicle_id: number, type: string | undefined = undefined) => {
  if (!type) {
    return async (dispatch: Dispatch<ActionsActions>) => {
      try {
        dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: true });
        const response = await api.getActionsByVehicle(vehicle_id);

        dispatch({
          type: ActionActionTypes.GET_ACTIONS,
          payload: response,
        });
        dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: false });
      } catch (e: any) {
        return e;
      }
    };
  } else {
    return async (dispatch: Dispatch<ActionsActions>) => {
      try {
        dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: true });
        const response = await api.getActionsByVehicleWithType(vehicle_id, type);

        dispatch({
          type: ActionActionTypes.GET_ACTIONS,
          payload: response,
        });
        dispatch({ type: ActionActionTypes.ACTION_LOADING, payload: false });
      } catch (e: any) {
        return e;
      }
    };
  }
};

export const visibleAction = (value: boolean) => {
  return {
    type: ActionActionTypes.VISIBLE_ACTIONS,
    payload: value,
  };
};

export const typeActions = (type: string) => {
  return {
    type: ActionActionTypes.TYPE_OF_EVENT_ACTIONS,
    payload: type,
  };
};

export const resetActionPeriod = (reset: boolean) => {
  return {
    type: ActionActionTypes.RESET_ACTION,
    payload: reset,
  };
};

export const inputTypeSearch = (type: string) => {
  return {
    type: ActionActionTypes.INPUT_TYPE,
    payload: type,
  };
};

export const selectValueUtil = (value: string) => {
  return {
    type: ActionActionTypes.SELECT_TYPE_UTIL,
    payload: value,
  };
};

export const selectValueJournal = (value: string) => {
  return {
    type: ActionActionTypes.SELECT_TYPE_JOURNAL_EVENT,
    payload: value,
  };
};

export const closeActionModal = (value: boolean) => {
  return {
    type: ActionActionTypes.CLOSE,
    payload: value,
  };
};

export const closeActionReset = () => {
  return {
    type: ActionActionTypes.CLOSE_RESET,
  };
};

export const detailTyreRepait = (value: any) => {
  return {
    type: ActionActionTypes.DETAIL_TYRE_REPAIR,
    payload: value,
  };
};
