import React, { useContext, useState } from "react";
import cls from "./style/Style.module.scss";
import imgOpen from "../../../assets/images/OpenProfile.svg";
import { Link } from "react-router-dom";
import { LinkMe } from "../Header.styled";
import { MobileContext } from "context/MobileContext";
import { UserState } from "redux/user/user_types";
import { useTypedSelector } from "hooks/useTypedSelector";

export default function Account() {
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<string>("Меню");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const user: UserState = useTypedSelector((state) => state.user);
  const { toggled, setToggled } = useContext(MobileContext);
  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlerOpen = () => {
    setIsOpen(false);
    setToggled(false);
  };

  return (
    <div className={cls.ctnMain}>
      <div className={cls.ctnMenu} onClick={() => handleClick()}>
        <div className={cls.ctnOffice}>
          <div className={cls.title}>{currentPage}</div>
        </div>
        <div className={cls.ctnImg}>
          <img className={`${cls.imgOpen} ${open ? cls.rotate : ""}`} src={imgOpen} alt="open" />
        </div>
      </div>

      <div
        className={`${cls.ctnOpenMenu} ${open ? cls.open : ""}`}
        onMouseLeave={() => handleClose()}
      >
        {(user?.data?.role === "admin" || user?.data?.role === "developer") && (
          <>
            <Link
              to="/admin/devices"
              title="Перейти в админку"
              className={cls.link}
              onClick={() => handlerOpen()}
            >
              Админка
            </Link>
          </>
        )}
        <Link className={cls.link} to="/tyres" onClick={() => handleClose()}>
          Журнал шин
        </Link>
        <Link className={cls.link} to="/profile" onClick={() => handleClose()}>
          Личный кабинет
        </Link>
      </div>
    </div>
  );
}
