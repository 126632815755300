function truncateFilename(filename: any, maxLength = 15) {
  if (filename.length <= maxLength) {
    return filename;
  }
  const extensionMatch = filename.match(/\.[0-9a-z]+$/i);
  const extension = extensionMatch ? extensionMatch[0] : "";
  const nameLength = maxLength - extension.length - 3;
  const truncatedName = filename.substr(0, nameLength);
  return `${truncatedName}...${extension}`;
}

export default truncateFilename;
