import { useActions } from "hooks/useAction";
import { useTypedSelector } from "hooks/useTypedSelector";
import { TyresState } from "redux/tyres/tyres_types";
import Loader from "components/loader/Loader";
import { CarState } from "redux/car/car_types";
import { IDesign } from "helpers/constructorTypes";
import VehicleTyre from "components/vehicle-view/VehicleTyre";
import TyreHeader from "components/tyres/TyreHeader";
import TyreTitle from "components/tyres/TyreTitle";
import TyreLine from "components/tyres/TyreLine";
import { CarsState } from "redux/cars/cars_types";
import SkeletonLoading from "components/skeleton-loading/SkeletonLoading";
import { useGetFoldersQuery } from "redux/folder/FolderApi";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import cls from "./style/TyresPage.module.scss";
import imgNoTyre from "../assets/images/noTyre.svg";
import { ConstructorState } from "redux/constructor/constructor_types";
import GeneralTyrePos from "components/constructor/placementTyre/GeneralTyrePos";
import ReportCount from "components/reports/report-components/ReportCount";
import { log } from "node:console";

const TyresPage = () => {
  const [countTyres, setCountTyres] = useState<any>(0);
  const [runTotalCar, setRunTotalCar] = useState<any>(0);
  const { toggleModal } = useActions();
  const { getCar, getCarTyres, closeActionReset } = useActions();
  const { vehicle_id } = useParams<{ vehicle_id?: string }>();
  const constructor: ConstructorState = useTypedSelector((state) => state.design);
  const { car }: CarState = useTypedSelector((state) => state.car);
  const { position_tyres } = useTypedSelector<any>((state) => state.car);

  const cars: CarsState = useTypedSelector((state) => state.cars);
  const { tyres_by_car, loading_tyres }: TyresState = useTypedSelector((state) => state.tyres);
  const { closeAction } = useTypedSelector((state) => state.actions);
  const { modelId, trailerId } = constructor;
  const { isLoading } = useGetFoldersQuery();
  const filtered_position = position_tyres?.filter((el: any) => el.carId === car?.id);
  const data_positions = filtered_position[0]?.positions;
  const init = () => {
    if (!vehicle_id) return;
    getCar(+vehicle_id);
    getCarTyres(+vehicle_id);
    setCountTyres(tyres_by_car?.length);
    setRunTotalCar(car?.last_point?.run_total);
  };

  useEffect(() => {
    init();
  }, [vehicle_id, closeAction]);

  const countRun = tyres_by_car?.map((el) => el.last_action?.run);
  const runTotal = countRun?.reduce((acc: any, el) => acc + el, 0);

  let design: null | IDesign = null;
  if (car && car.design)
    design = {
      modelId: car.design.model_id,
      trailerId: car.design.trailer_id,
      positions: car.design.positions,
    };

  let vehickeModel = <></>;

  if (design) {
    vehickeModel = <VehicleTyre design={design} />;
  }

  const handlePositions = () => {
    // console.log("click position");
  };
  const titles = [
    "ШИНА",
    "ДАТА УСТАНОВКИ",
    "DOT",
    "МЕСТОПОЛОЖЕНИЕ",
    "ПРОБЕГ",
    "ГЛУБИНА ПРОТЕКТОРА",
  ];

  const handlClick = () => {
    toggleModal({ toggle: true, modal: "modal_add_tyre" });
  };

  return (
    <>
      {cars.loading && isLoading ? (
        <SkeletonLoading height="100vh" />
      ) : (
        <div className="report_head">
          {loading_tyres ? (
            <Loader />
          ) : (
            <>
              {tyres_by_car?.length != 0 && (
                <>
                  <TyreTitle
                    title="Общая"
                    subtitle="информация"
                    btnTitle="добавить шину"
                    handleClick={handlClick}
                  />
                </>
              )}
              {tyres_by_car?.length != 0 && vehicle_id == car?.id && (
                <div className={cls.ctnInfoTyre}>
                  <div className={cls.reportCount}>
                    <ReportCount
                      count={countTyres || tyres_by_car?.length}
                      title1="количество"
                      title2="шин"
                    />
                  </div>
                  <div className={cls.modelContainer}>
                    {car?.design != null && modelId && car && car?.position_tyres ? (
                      <div className={cls.ctnConstructorTitle}>
                        <GeneralTyrePos
                          car={car}
                          tyres_by_car={tyres_by_car}
                          modelId={modelId}
                          defaultPositions={data_positions || car?.position_tyres}
                          trailerId={trailerId}
                          wheelCount={8}
                          handlePositions={handlePositions}
                          tyres={data_positions || car?.position_tyres} /// пробовать вытаскивать из шины
                        />
                        {/* <TyrePositionConst modelId={modelId} /> */}
                        <div className={cls.carTittle}>{car.title}</div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <ReportCount count={runTotalCar} title1="общий" title2="пробег" />
                  </div>
                </div>
              )}
              {tyres_by_car?.length != 0 && (
                <TyreHeader titles={titles} classes="tyre_table_box-5" />
              )}
              {tyres_by_car && tyres_by_car.length > 0 ? (
                tyres_by_car.map((tyre) => <TyreLine key={tyre.id} {...tyre} />)
              ) : (
                <div className={cls.ctNoTyre}>
                  <div className={cls.ctnImgTyre}>
                    <img src={imgNoTyre} alt="tyre" />
                  </div>
                  <div className={cls.ctnInfo}>
                    <div className={cls.text}>У вас не добавлено ни одной шины</div>
                    <button type="submit" className={cls.btnAddTyre} onClick={handlClick}>
                      Добавить шину
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TyresPage;
