import ActionLine from "components/actions/ActionLine";
import Loader from "components/loader/Loader";
// import SkeletonLoading from "components/skeleton-loading/SkeletonLoading";
import TyreHeader from "components/tyres/TyreHeader";
import TyreInfo from "components/tyres/TyreInfo";
// import TyreLine from "components/tyres/TyreLine";
import TyreTitle from "components/tyres/TyreTitle";
import { useActions } from "hooks/useAction";
import { useTypedSelector } from "hooks/useTypedSelector";
import ActionItemLine from "pages/actions/components/ActionItemLine";
// import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ActionsState } from "redux/actions/actions_types";
import { CarState } from "redux/car/car_types";
import cls from "../style/TyresPage.module.scss";
import GeneralTyrePos from "components/constructor/placementTyre/GeneralTyrePos";
import { ConstructorState } from "redux/constructor/constructor_types";
import ReportCount from "components/reports/report-components/ReportCount";

const TyresHistoryPage = () => {
  const titles = [
    "ДАТА",
    "СОБЫТИЕ",
    "ТРАНСПОРТ",
    "ШИНА",
    "МЕСТОПОЛОЖЕНИЕ",
    "ПРОБЕГ",
    "ГЛУБИНА ПРОТЕКТОРА",
    "",
  ];
  const { vehicle_id } = useParams<{ vehicle_id?: string }>();
  console.log(vehicle_id);

  const { getActionsByVehicle, getCarTyres } = useActions();
  const [count, setCount] = useState<number>(0);
  const { actions, loading }: ActionsState = useTypedSelector((state) => state.actions);
  const constructor: ConstructorState = useTypedSelector((state) => state.design);
  const { position_tyres } = useTypedSelector<any>((state) => state.car);
  const { modelId, trailerId } = constructor;
  const { tyres_by_car, loading_tyres, tyre }: any = useTypedSelector((state) => state.tyres);
  // const today = moment(new Date()).format("YYYY-MM-DD");
  const { car }: CarState = useTypedSelector((state) => state.car);
  const countRun = tyres_by_car?.map((el: any) => el.last_action?.run);
  const runTotal = countRun?.reduce((acc: any, el: any) => acc + el, 0);

  const init = async () => {
    if (!vehicle_id) return;
    getActionsByVehicle(+vehicle_id);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    actions && actions.count ? setCount(actions.count) : setCount(0);
  }, [actions]);
  const handlClick = () => {
    console.log("click");
  };
  const { get_constructor_all } = useTypedSelector<any>((state) => state.design);
  const desingExisting = (id: number) => {
    const item = get_constructor_all?.data.find((el: any) => el.vehicle_id === id);
    return { modelId: item?.model_id, trailerId: item?.trailer_id };
  };
  const filtered_position = position_tyres?.filter((el: any) => el.carId === car?.id);
  const data_positions = filtered_position[0]?.positions;

  const handlePositions = () => {};

  const renderActionItem = (action: any) => {
    <ActionItemLine
      key={action.id}
      action_id={action.id}
      date={action.updated_at}
      type={action.type}
      car_title={
        action?.tyre?.history_tyres[0]?.vehicle_name ||
        action?.tyre?.vehicle?.title ||
        "No title available"
      }
      run={action.run || "N/A"}
      tread={action.tread || "N/A"}
      position_tyre={action?.tyre?.history_tyres[0]?.position_tyre || action?.tyre?.position_tyre}
      model={
        action?.tyre?.history_tyres[0]?.model_id ||
        desingExisting(action?.tyre?.vehicle?.id).modelId
      }
      trailer={
        action?.tyre?.history_tyres[0]?.trailer_id ||
        desingExisting(action?.tyre?.vehicle?.id).trailerId ||
        null
      }
    />;
  };

  return (
    <div className="report_head">
      {loading ? (
        <Loader />
      ) : (
        <>
          <TyreTitle title="История" subtitle="шин" />
          {/* <TyreInfo
            firstCount={{
              count: actions?.actions.length,
              title1: "количество",
              title2: "событий",
            }}
            secondCount={{ count: count, title1: "общий", title2: "пробег" }}
            showConstructor={false}
          /> */}

          <div className={cls.ctnInfoTyre}>
            <div className={cls.reportCount}>
              <ReportCount count={actions?.actions.length} title1="количество" title2="шин" />
            </div>
            <div className={cls.modelContainer}>
              {car?.design != null && modelId && car && car?.position_tyres ? (
                <div className={cls.ctnConstructorTitle}>
                  <GeneralTyrePos
                    car={car}
                    tyres_by_car={tyres_by_car}
                    modelId={modelId}
                    defaultPositions={data_positions || car?.position_tyres}
                    trailerId={trailerId}
                    wheelCount={8}
                    handlePositions={handlePositions}
                    tyres={data_positions || car?.position_tyres} /// пробовать вытаскивать из шины
                  />
                  {/* <TyrePositionConst modelId={modelId} /> */}
                  <div className={cls.carTittle}>{car.title}</div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              <ReportCount count={runTotal} title1="общий" title2="пробег" />
            </div>
          </div>
          {actions && actions.actions.length > 0 ? (
            <>
              <TyreHeader titles={titles} classes="tyre_table_box-8" />
              {actions.actions.map((action) => (
                <ActionLine
                  key={action.id}
                  date={action.updated_at}
                  type={action.type}
                  car_title={
                    action.tyre && action.tyre.vehicle?.title
                      ? action.tyre.vehicle?.title
                      : "ТС не привязано"
                  }
                  tyre_model={action.tyre?.model}
                  tyre_brand={action.tyre?.brand}
                  run={action.run}
                  tread={action.tread}
                  modelID={car?.design?.model_id}
                  trailerId={car?.design?.trailer_id}
                  tyre={action?.tyre}
                />
              ))}
            </>
          ) : (
            <div className="border-text">Ничего не найдено</div>
          )}
        </>
      )}
    </div>
  );
};

export default TyresHistoryPage;
