import { IPoint } from "../map/map_types";
import { CarActions, CarActionTypes, CarState, ICar } from "./car_types";

const initialState: CarState = {
  car: null,
  loading: true,
  car_extended: null,
  loading_extended: true,
  last_point: null,
  loading_point: true,
  calendarData: null,
  position_tyres: [],
};

const CarReducer = (state = { ...initialState }, action: CarActions) => {
  switch (action.type) {
    case CarActionTypes.GET_CAR:
      return {
        ...state,
        car: action.payload,
        loading: false,
      };
    case CarActionTypes.CAR_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CarActionTypes.GET_CAR_EXTENDED:
      return {
        ...state,
        car_extended: action.payload,
        loading_extended: false,
      };

    case CarActionTypes.LOADING_EXTENDED:
      return {
        ...state,
        loading_extended: action.payload,
      };

    case CarActionTypes.CHANGE_INPUT_CAR_EXTENDED: {
      const _car_extended = { ...state.car_extended };
      _car_extended[action.payload.key] = action.payload.value;

      return {
        ...state,
        car_extended: _car_extended,
      };
    }
    case CarActionTypes.GET_LAST_POINT:
      return {
        ...state,
        last_point: action.payload,
        loading_point: false,
      };
    case CarActionTypes.RESET_CAR_EXTENDED:
      return {
        ...state,
        car: {} as ICar,
        loading_extended: true,
      };
    case CarActionTypes.RESET_CAR:
      return {
        ...initialState,
      };
    case CarActionTypes.CAR_UPDATE_LAST_POINT_ADDRESS:
      const __last_point = { ...state.last_point, address: action.payload } as IPoint;

      return {
        ...state,
        last_point: __last_point,
      };
    case CarActionTypes.CAR_CHANGE_CONSTRUCTOR: {
      const newCar = { ...state.car };
      newCar.design = action.payload;

      return {
        ...state,
        car: newCar,
      };
    }
    case CarActionTypes.CAR_CHANGE_LIMIT: {
      const newCar = { ...state.car };
      newCar.last_limit = action.payload;

      return {
        ...state,
        car: newCar,
      };
    }
    case CarActionTypes.GET_CALENDAR:
      return {
        ...state,
        calendarData: action.payload,
        loading: false,
      };
    case CarActionTypes.GET_ID_CAR_STORE:
      return {
        ...state,
        id_car_store: action.payload,
      };
    case CarActionTypes.CAR_TYRE_POSITION:
      const { carId, positions }: any = action.payload;

      // if (!Array.isArray(state.position_tyres)) {
      //   return {
      //     ...state,
      //     position_tyres: [],
      //   };
      // }
      // Ищем, есть ли уже запись для данного carId
      const existingCarData: any = state.position_tyres.find((item: any) => item.carId === carId);

      if (existingCarData) {
        // Если запись есть, обновляем позиции шин, убирая дубликаты
        const updatedCarData = {
          ...existingCarData,
          positions: [...new Set([...existingCarData.positions, ...positions])],
        };

        return {
          ...state,
          position_tyres: state.position_tyres.map((item: any) =>
            item.carId === carId ? updatedCarData : item
          ),
        };
      } else {
        // Если записи нет, добавляем новую
        return {
          ...state,
          position_tyres: [
            ...state.position_tyres,
            {
              carId: carId,
              positions: [...new Set(positions)], // Убираем дубликаты позиций для нового объекта
            },
          ],
        };
      }
    // const { car_id, positions }: any = action.payload;
    // return {
    //   ...state,
    //   position_tyres: [...state.position_tyres, { car_id: car_id, positions: [...positions] }],
    // };
    default:
      return state;
  }
};

export default CarReducer;
