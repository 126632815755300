import { Dispatch } from "redux";
import { STEPS } from "../../helpers/constructorTypes";
import { ConstructorActions, ConstructorActionTypes } from "./constructor_types";
import api from "api";

export const changeStep = (payload: string) => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.CHANGE_STEP, payload });
  };
};

export const goToCategories = () => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.CHANGE_CATEGORY, payload: 0 });
    dispatch({ type: ConstructorActionTypes.CHANGE_STEP, payload: STEPS.CATEGORIES });
  };
};

export const changeCategory = (payload: number) => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.CHANGE_CATEGORY, payload });
  };
};

export const changeModel = (payload: null | number) => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.CHANGE_MODEL, payload });
  };
};

export const changeTrailer = (payload: null | number) => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.CHANGE_TRAILER, payload });
  };
};

export const resetConstructor = () => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.RESET_CONSTRUCTOR });
  };
};

export const changePosition = (payload: number[]) => {
  return async (dispatch: Dispatch<ConstructorActions>) => {
    dispatch({ type: ConstructorActionTypes.CHANGE_POSITION, payload });
  };
};

export const getConstructorByVehicle = (vehicle_id: any) => {
  try {
    return async (dispatch: Dispatch<ConstructorActions>) => {
      dispatch({ type: ConstructorActionTypes.LOADING_CONSTRUCTOR, payload: true });
      const response = await api.getConstructorByVehicle(vehicle_id);
      dispatch({
        type: ConstructorActionTypes.GET_CONSTRUCTOR,
        payload: response.data,
      });
      dispatch({ type: ConstructorActionTypes.LOADING_CONSTRUCTOR, payload: false });
    };
  } catch (error) {
    console.log(error);
  }
};

export const getConstructorAll = () => {
  try {
    return async (dispatch: Dispatch<ConstructorActions>) => {
      dispatch({ type: ConstructorActionTypes.LOADING_CONSTRUCTOR, payload: true });
      const response = await api.getConstructorAll();
      dispatch({
        type: ConstructorActionTypes.GET_CONSTRUCTOR_ALL,
        payload: response.data,
      });
      dispatch({ type: ConstructorActionTypes.LOADING_CONSTRUCTOR, payload: false });
    };
  } catch (error) {
    console.log(error);
  }
};
