const LoginContactUs = () => {
  return (
    <div className="login__contact-us">
      <a href="tel:+84957276700" className="login__phone">
        8 (495) 727-67-00
      </a>{" "}
      — ПН-ПТ С 08:00 ДО 17:00 |{" "}
      <a href="mailto:nk@tyrecontrol.ru" className="login__email">
        nk@tyrecontrol.ru
      </a>
    </div>
  );
};

export default LoginContactUs;
