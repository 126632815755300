import { Outlet } from "react-router-dom";
import ModalAddDevice from "../components/admin/ModalAddDevice";
import ModalCreateNewUser from "../components/modals/ModalCreateNewUser";
import ModalConfirm from "../components/modals/ModalConfirm";
import ModalSetNewPassword from "../components/modals/ModalSetNewPassword";
import ModalNotifications from "../components/modals/ModalNotifications";
import ModalCarExtendedInfo from "../components/modals/ModalCarExtendedInfo";
import ModalGeneralSettings from "../components/modals/ModalGeneralSettings";
import ModalAddTransport from "../components/modals/ModalAddTransport";
import ModalEditUserData from "../components/modals/ModalEditUserData";
import { CarsModalProvider } from "../context/CarsModalContext";
import Header from "../components/header/Header";
import ModalPoint from "../components/modals/ModalPoint";
import AddressLoader from "../components/address-loader/AddressLoader";
import ModalFolders from "../components/modals/ModalFolders";
import ModalNewPasswordUserByModerator from "../components/modals/ModalNewPasswordUserByModerator";
import ModalUserCarChange from "components/modals/ModalUserCarChange";
import ModalUserCarAdd from "components/modals/ModalUserCarAdd";
import ModalProfileChange from "components/modals/ModalProfileChange";
import ModalUpdateUser from "components/modals/ModalUpdateUser";

import ModalAddTyre from "../components/modals/ModalAddTyre";
import ModalAddAction from "components/modals/ModalAddAction";
import ModalUpdateTyre from "components/modals/ModalUpdateTyre";

import ModalChangeFolder from "components/modals/ModalChangeFolder";

import ModalInstalltionOnTransport from "components/modals/ModalInstalltionOnTransport";
import ModalRepairTyre from "components/modals/ModalRepairTyre";
import ModalUtilizationTyre from "components/modals/ModalUtilizationTyre";

import ModalTransferCars from "components/modals/ModalTransferCars";

const PrivateLayout = () => {
  return (
    <>
      <Header />
      <Outlet />

      <AddressLoader />

      {/* МОДАЛКИ */}
      <CarsModalProvider>
        <ModalFolders />
      </CarsModalProvider>

      <ModalCarExtendedInfo />
      <ModalGeneralSettings />
      <ModalAddTransport />
      <ModalEditUserData />
      <ModalSetNewPassword />
      <ModalNotifications />
      <ModalAddDevice />
      <ModalCreateNewUser />
      <ModalPoint />
      <ModalConfirm />
      <ModalTransferCars />
      <ModalNewPasswordUserByModerator />
      <ModalUserCarChange />
      <ModalUserCarAdd />
      <ModalProfileChange />
      <ModalUpdateUser />
      <ModalAddTyre />
      <ModalAddAction />
      <ModalUpdateTyre />
      <ModalInstalltionOnTransport />
      <ModalRepairTyre />
      <ModalUtilizationTyre />
      <ModalNewPasswordUserByModerator />
      <ModalUserCarChange />
      <ModalUserCarAdd />
      <ModalProfileChange />
      <ModalUpdateUser />
      <ModalChangeFolder />
    </>
  );
};

export default PrivateLayout;
