import TyreDate from "components/tyres/TyreDate";
import { TyreListProps } from "./TyreList";
import TyreHeader from "components/tyres/TyreHeader";
import moment from "moment";
import { ReactComponent as Arrow } from "../../../assets/images/Arrow2.svg";
import { Link } from "react-router-dom";
import { useTypedSelector } from "hooks/useTypedSelector";

const TyreListUtilization = ({ from, to, titles, tyres }: TyreListProps) => {
  const { select_value_util } = useTypedSelector((state) => state.actions);

  const priceKmTyre = (price: number, run: number) => {
    let result = 0;
    result = (price / run) * 100;
    return result.toFixed(2);
  };

  const tyresFillter = tyres.filter(
    (tyre: any) => tyre?.action?.utilization?.reason === select_value_util
  );

  return (
    <>
      <TyreDate from={from} to={to} />
      {tyres && tyres.length > 0 ? (
        <>
          <TyreHeader titles={titles} />
          {select_value_util === "all" ? (
            tyres?.map((tyre: any) => (
              <div className="tyre_line tyre_table_box" key={tyre.id}>
                <div className="tyre_line_part bold">{`${tyre.model} ${tyre.brand}`}</div>
                <div className="tyre_line_part">
                  {tyre.last_move && tyre.last_move?.updated_at ? (
                    `${moment(tyre.last_move?.updated_at).format("YYYY-MM-DD")}/${moment(
                      tyre.action?.updated_at
                    ).format("YYYY-MM-DD")}`
                  ) : (
                    <div>не верная дата</div>
                  )}
                </div>
                <div className="tyre_line_part tyre_line_part--white-space">
                  {tyre.action.utilization?.reason}
                </div>
                <div className="tyre_line_part">
                  {tyre?.action && tyre?.action !== null ? <div>{tyre.action.run}</div> : 0}
                </div>
                <div className="tyre_line_part">
                  {priceKmTyre(tyre?.price, tyre?.action?.run)} р.
                </div>
                <div>
                  <Link className="report_time-more" to={`/tyres/utilization/${tyre.id}`}>
                    <span className="time-more_text">Подробнее</span>
                    <Arrow className="arrow" />
                  </Link>
                </div>
              </div>
            ))
          ) : tyresFillter && tyresFillter.length === 0 ? (
            <div className="border-text">Ничего не найдено</div>
          ) : (
            tyresFillter?.map((tyre: any) => (
              <div className="tyre_line tyre_table_box" key={tyre.id}>
                <div className="tyre_line_part bold">{`${tyre.model} ${tyre.brand}`}</div>
                <div className="tyre_line_part">
                  {tyre.last_move && tyre.last_move?.updated_at ? (
                    `${moment(tyre.last_move?.updated_at).format("YYYY-MM-DD")}/${moment(
                      tyre.action?.updated_at
                    ).format("YYYY-MM-DD")}`
                  ) : (
                    <div>не верная дата</div>
                  )}
                </div>
                <div className="tyre_line_part tyre_line_part--white-space">
                  {tyre.action.utilization?.reason}
                </div>
                <div className="tyre_line_part">
                  {tyre?.action && tyre?.action !== null ? <div>{tyre.action.run}</div> : 0}
                </div>
                <div className="tyre_line_part">
                  {priceKmTyre(tyre?.price, tyre?.action?.run)} р.
                </div>
                <div>
                  <Link className="report_time-more" to={`/tyres/utilization/${tyre.id}`}>
                    <span className="time-more_text">Подробнее</span>
                    <Arrow className="arrow" />
                  </Link>
                </div>
              </div>
            ))
          )}
        </>
      ) : (
        <div className="border-text">Ничего не найдено</div>
      )}
    </>
  );
};

export default TyreListUtilization;
