import Loader from "components/loader/Loader";
import { useEffect, useMemo, useState } from "react";
import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useAction";
import { ActionsState } from "redux/actions/actions_types";
import moment from "moment";
import TyreTitle from "components/tyres/TyreTitle";
import TyreInfo from "components/tyres/TyreInfo";
import TyreDate from "components/tyres/TyreDate";
import MobileSwitch from "components/mobile-toggler/MobileSwitch";
import DashboardWrapper from "layouts/DashboardWrapper";
import SideBar from "components/sideBar/SideBar";
import TyresHeader from "components/tyres/TyresHeader";
import SideBarTyresAction from "components/sideBar/SideBarTyresAction";
import CustomScroll from "react-custom-scroll";
import TitleJournal from "./components/TitleJournal";
import ActionItemLine from "./components/ActionItemLine";

const JournalPage = () => {
  const { getActionsPeriod, getConstructorAll, getAxelActionsAll } = useActions();
  const [count, setCount] = useState<number>(0);
  const { actions, loading, select_type_journal, axel_action_all }: ActionsState = useTypedSelector(
    (state) => state.actions
  );

  const [actionLength, setActionLength] = useState<number>(0);
  const [filteredLength, setFilteredLength] = useState<number>(0);
  const { get_constructor_all } = useTypedSelector<any>((state) => state.design);
  const today = moment(new Date()).format("YYYY-MM-DD");
  const desingExisting = (id: number) => {
    const item = get_constructor_all?.data.find((el: any) => el.vehicle_id === id);
    return { modelId: item?.model_id, trailerId: item?.trailer_id };
  };

  const filteredActions =
    actions?.actions?.filter((action) => action.type === select_type_journal) || actions?.actions;
  const totalRun = filteredActions?.reduce((acc, action) => acc + (action.run || 0), 0) || 0;
  const totalRunAll = actions?.actions?.reduce((acc, action) => acc + (action.run || 0), 0) || 0;
  const init = async () => {
    try {
      await Promise.all([getActionsPeriod(today, today), getConstructorAll(), getAxelActionsAll()]);
    } catch (error) {
      console.error("Ошибка при выполнении запросов:", error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (select_type_journal === "all") {
      const length = actions?.actions?.length || 0;
      setActionLength(length);
      setFilteredLength(0);
      setCount(totalRunAll);
    } else {
      const length = filteredActions?.length !== undefined ? filteredActions.length : 0;
      setFilteredLength(length);
      setCount(totalRun);
    }
  }, [actions, filteredActions, select_type_journal]);
  const finalLength = select_type_journal === "all" ? actionLength : filteredLength;

  const renderActionItem = (action: any) => (
    <ActionItemLine
      key={action.id}
      action_id={action.id}
      date={action.updated_at}
      type={action.type}
      car_title={
        action?.tyre?.history_tyres[0]?.vehicle_name ||
        action?.tyre?.vehicle?.title ||
        "No title available"
      }
      run={action.run || "N/A"}
      tread={action.tread || "N/A"}
      position_tyre={action?.tyre?.history_tyres[0]?.position_tyre || action?.tyre?.position_tyre}
      model={
        action?.tyre?.history_tyres[0]?.model_id ||
        desingExisting(action?.tyre?.vehicle?.id).modelId
      }
      trailer={
        action?.tyre?.history_tyres[0]?.trailer_id ||
        desingExisting(action?.tyre?.vehicle?.id).trailerId ||
        null
      }
    />
  );

  return (
    <DashboardWrapper>
      <SideBar>
        <SideBarTyresAction />
      </SideBar>
      <div className="dashboard__view">
        <MobileSwitch />
        <TyresHeader />
        <div className="tyre_container">
          <div className="report">
            <CustomScroll
              allowOuterScroll={false}
              heightRelativeToParent="100%"
              className="rcs-custom-scroll--center"
            >
              <div className="report_box">
                <div className="report_head">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <TyreTitle title="Журнал" subtitle="событий" />
                      <TyreInfo
                        firstCount={{
                          count: finalLength,
                          title1: "количество",
                          title2: "событий",
                        }}
                        secondCount={{
                          count: count,
                          title1: "общий",
                          title2: "пробег",
                        }}
                        showConstructor={false}
                      />
                      <TyreTitle title="События" subtitle="за период" />
                      <TyreDate from={actions?.from || today} to={actions?.to} />
                      <TitleJournal />
                      {((select_type_journal === "all" ? actions?.actions : filteredActions)
                        ?.length || 0) > 0 ? (
                        (select_type_journal === "all" ? actions?.actions : filteredActions)?.map(
                          renderActionItem
                        )
                      ) : (
                        <div className="border-text">Ничего не найдено</div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </CustomScroll>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default JournalPage;
