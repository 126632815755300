import ActionLine from "components/actions/ActionLine";
import GeneralTyrePos from "components/constructor/placementTyre/GeneralTyrePos";
import Loader from "components/loader/Loader";
import ReportCount from "components/reports/report-components/ReportCount";
import TyreHeader from "components/tyres/TyreHeader";
import TyreInfo from "components/tyres/TyreInfo";
import TyreTitle from "components/tyres/TyreTitle";
import { useActions } from "hooks/useAction";
import { useTypedSelector } from "hooks/useTypedSelector";
// import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ActionsState } from "redux/actions/actions_types";
import { ConstructorState } from "redux/constructor/constructor_types";
import { TyresState } from "redux/tyres/tyres_types";
import cls from "../style/TyresPage.module.scss";
import { CarState } from "redux/car/car_types";
import ActionItemLine from "pages/actions/components/ActionItemLine";
// import { CarState } from "redux/car/car_types";

const TyresRepairPage = () => {
  const { vehicle_id } = useParams<{ vehicle_id?: string }>();
  const titles = [
    "ДАТА",
    "СОБЫТИЕ",
    "ТРАНСПОРТ",
    "ШИНА",
    "МЕСТОПОЛОЖЕНИЕ",
    "ПРОБЕГ",
    "ГЛУБИНА ПРОТЕКТОРА",
    "",
  ];

  const { getActionsByVehicle, getCar, getCarTyres, getAxelActionsAll } = useActions();
  const [count, setCount] = useState<number>(0);
  const [countTyres, setCountTyres] = useState<any>(0);
  const { car }: CarState = useTypedSelector((state) => state.car);
  const constructor: ConstructorState = useTypedSelector((state) => state.design);
  const { actions, loading }: ActionsState = useTypedSelector((state) => state.actions);
  const { position_tyres } = useTypedSelector<any>((state) => state.car);
  const { tyres_by_car, loading_tyres, tyre }: TyresState = useTypedSelector(
    (state) => state.tyres
  );
  const { modelId, trailerId } = constructor;
  const filtered_position = position_tyres?.filter((el: any) => el.carId === car?.id);
  const data_positions = filtered_position[0]?.positions;
  const countRun = tyres_by_car?.map((el) => el.last_action?.run);
  const runTotal = countRun?.reduce((acc: any, el) => acc + el, 0);

  const init = async () => {
    if (!vehicle_id) return;
    getCar(+vehicle_id);
    getCarTyres(+vehicle_id);
    getActionsByVehicle(+vehicle_id, "repair");
    setCountTyres(tyres_by_car?.length);
  };

  useEffect(() => {
    init();
  }, [vehicle_id]);

  useEffect(() => {
    actions && actions.count ? setCount(actions.count) : setCount(0);
  }, [actions]);

  const handlClick = () => {
    console.log("click");
  };
  const handlePositions = () => {
    // console.log("click position");
  };
  const { get_constructor_all } = useTypedSelector<any>((state) => state.design);
  const desingExisting = (id: number) => {
    const item = get_constructor_all?.data.find((el: any) => el.vehicle_id === id);
    return { modelId: item?.model_id, trailerId: item?.trailer_id };
  };

  const renderActionItem = (action: any) => {
    <ActionItemLine
      key={action.id}
      action_id={action.id}
      date={action.updated_at}
      type={action.type}
      car_title={action?.tyre?.vehicle?.title || "No title available"}
      run={action.run || "N/A"}
      tread={action.tread || "N/A"}
      position_tyre={action?.tyre?.history_tyres[0]?.position_tyre || action?.tyre?.position_tyre}
      model={
        action?.tyre?.history_tyres[0]?.model_id ||
        desingExisting(action?.tyre?.vehicle?.id).modelId
      }
      trailer={
        action?.tyre?.history_tyres[0]?.trailer_id ||
        desingExisting(action?.tyre?.vehicle?.id).trailerId ||
        null
      }
    />;
  };

  return (
    <div className="report_head">
      {loading ? (
        <Loader />
      ) : (
        <>
          <TyreTitle title="Ремонт" subtitle="шин" />
          {/* <TyreInfo
            firstCount={{
              count: actions?.actions.length,
              title1: "количество",
              title2: "событий",
            }}
            secondCount={{ count: count, title1: "общий", title2: "пробег" }}
            showConstructor={false}
          /> */}
          {tyres_by_car?.length != 0 && vehicle_id == car?.id && (
            <div className={cls.ctnInfoTyre}>
              <div className={cls.reportCount}>
                <ReportCount
                  count={countTyres || tyres_by_car?.length}
                  title1="количество"
                  title2="шин"
                />
              </div>
              <div className={cls.modelContainer}>
                {car?.design != null && modelId && car && car?.position_tyres ? (
                  <div className={cls.ctnConstructorTitle}>
                    <GeneralTyrePos
                      car={car}
                      tyres_by_car={tyres_by_car}
                      modelId={modelId}
                      defaultPositions={data_positions || car?.position_tyres}
                      trailerId={trailerId}
                      wheelCount={8}
                      handlePositions={handlePositions}
                      tyres={data_positions || car?.position_tyres} /// пробовать вытаскивать из шины
                    />
                    {/* <TyrePositionConst modelId={modelId} /> */}
                    <div className={cls.carTittle}>{car.title}</div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <ReportCount count={runTotal} title1="общий" title2="пробег" />
              </div>
            </div>
          )}
          {actions && actions.actions.length > 0 ? (
            <>
              <TyreHeader titles={titles} classes="tyre_table_box-8" />
              {actions.actions.map((action) => (
                <ActionLine
                  key={action.id}
                  date={action.updated_at}
                  type={action.type}
                  car_title={
                    action.tyre && action.tyre.vehicle?.title
                      ? action.tyre.vehicle?.title
                      : "ТС не привязано"
                  }
                  tyre_model={action.tyre?.model}
                  tyre_brand={action.tyre?.brand}
                  run={action.run}
                  tread={action.tread}
                  modelID={car?.design?.model_id}
                  trailerId={car?.design?.trailer_id}
                  tyre={action?.tyre}
                />
              ))}
            </>
          ) : (
            <div className="border-text">Ничего не найдено</div>
          )}
        </>
      )}
    </div>
  );
};

export default TyresRepairPage;
