import { ACTION_TYPES } from "constants/actions";
import { ReactComponent as Arrow } from "../../assets/images/Arrow2.svg";
import moment from "moment";
import TyrePositionList from "components/constructor/placementTyre/TyrePositionList";

const ActionLine = ({
  date,
  type,
  tyre_model,
  run,
  tread,
  car_title,
  tyre_brand,
  modelID,
  trailerId,
  tyre,
}: ActionLineProps) => {
  const handlePoint = () => {
    console.log("click more");
  };
  console.log(trailerId);
  const handlePosition = () => {};

  return (
    <div className="tyre_line tyre_table_box tyre_table_box-8">
      <div className="tyre_line_part bold">{moment(date).format("DD MMMM")}</div>
      <div className="tyre_line_part">{ACTION_TYPES[type]}</div>
      <div className="tyre_line_part tyre_line_part--white-space">{car_title}</div>
      <div className="tyre_line_part tyre_line_part--white-space">
        {`${tyre_brand} ${tyre_model}`}
      </div>
      <div className="tyre_line_part" style={{ transform: "scale(0.8)" }}>
        {modelID && tyre?.position_tyre ? (
          <TyrePositionList
            modelId={modelID}
            defaultPositions={tyre.position_tyre}
            trailerId={trailerId}
            wheelCount={8}
            handlePositions={handlePosition}
            tyre={tyre.position_tyre}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="tyre_line_part">{run}</div>
      <div className="tyre_line_part">{tread}</div>
      <button className="report_time-more" onClick={() => handlePoint()}>
        <span className="time-more_text">Подробнее</span>
        <Arrow className="arrow" />
      </button>
    </div>
  );
};

export default ActionLine;

export interface ActionLineProps {
  date: string;
  type: string;
  car_title: string | null | undefined;
  tyre_model: string;
  tyre_brand: string;
  run: number;
  tread: number;
  modelID: any;
  trailerId: any;
  tyre: any;
}
