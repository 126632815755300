const ALL = "Все типы событий";
const ADDING = "Установка на транспортное средство";
const MOVING = "Перемещение на транспортное средство";
const STORE = "Перемещение на склад";
const REPAIR = "Ремонт";
const UTILIZATION = "Утилизация";
const AXEL_CHANGE = "Смена оси";

export const ACTION_TYPES: ActionTypesConstProps = {
  all: ALL,
  adding: ADDING,
  moving: MOVING,
  store: STORE,
  repair: REPAIR,
  utilization: UTILIZATION,
  axel_change: AXEL_CHANGE,
};

interface ActionTypesConstProps {
  [key: string]: string;
}
