import { useContext, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import ModalInput from "../ui/Inputs/ModalInput";
import ModalWindow from "./ModalWindow";
import { ModalUserCarContext } from "components/user/context/ModalUserCarContext";
import api from "api";
import SkeletonLoading from "components/skeleton-loading/SkeletonLoading";
import { toast } from "react-hot-toast";
import { useGetDevicesQuery } from "../../redux/admin/devices/DevicesApi";

const ModalUserCarChange = () => {
  const { toggleModal } = useActions();

  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const { userCurrent, setuserCarList, carUser, setCarUser } = useContext(ModalUserCarContext);
  const [submiting, setSubmiting] = useState<boolean>(false);
  const { refetch } = useGetDevicesQuery();
  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_user_car_change" });
    setSubmiting(false);
  };

  const handleChangeInput = (key: string, value: string) => {
    setCarUser({
      ...carUser,
      [key]: value,
    });
  };
  const handleSave = async () => {
    setSubmiting(true);
    try {
      await api.putCar(carUser.id, {
        region: carUser.region,
        car_number: carUser.car_number,
        comment: carUser.comment,
        title: carUser.title,
      });
      refetch();
      await api.getUserCars(userCurrent).then((res) => {
        setuserCarList(res.vehicles);
      });
      toast.success("Данные изменены");
      handleClose();
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setSubmiting(false);
    setCarUser({});
  };

  return (
    <ModalWindow
      isShow={modals.modal_user_car_change}
      title="Изменение данных ТС"
      handleClose={handleClose}
    >
      {submiting ? (
        <SkeletonLoading height="388px" />
      ) : (
        <>
          <div className="modal-content__box">
            <ModalInput
              title="Гос номер"
              value={carUser.car_number}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                handleChangeInput("car_number", (e.target as HTMLInputElement).value);
              }}
              errors={[]}
            />
            <ModalInput
              title="Регион"
              value={carUser.region}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                handleChangeInput("region", (e.target as HTMLInputElement).value);
              }}
              errors={[]}
            />
            <ModalInput
              title="Марка ТС"
              value={carUser.title}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                handleChangeInput("title", (e.target as HTMLInputElement).value);
              }}
              errors={[]}
            />
            <ModalInput
              title="Комментарий"
              value={carUser.comment}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                handleChangeInput("comment", (e.target as HTMLInputElement).value);
              }}
              errors={[]}
            />
          </div>
          <div className="modal-folder__button-box">
            <button
              className="modal__button modal__button--white modal__button--red"
              onClick={() => handleClose()}
            >
              Отменить
            </button>
            <button className="modal__button " onClick={(e) => handleSave()}>
              Сохранить
            </button>
          </div>
        </>
      )}
    </ModalWindow>
  );
};

export default ModalUserCarChange;
