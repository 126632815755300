import ActionLine from "components/actions/ActionLine";
import ActionLineUtilDetail from "components/actions/ActionLineUtilDetail";
import Loader from "components/loader/Loader";
import TyreDate from "components/tyres/TyreDate";
import TyreHead from "components/tyres/TyreHead";
import TyreHeader from "components/tyres/TyreHeader";
import TyreTitle from "components/tyres/TyreTitle";
import { useActions } from "hooks/useAction";
import { useTypedSelector } from "hooks/useTypedSelector";
import moment from "moment";
import ActionItemLine from "pages/actions/components/ActionItemLine";
import TitleJournal from "pages/actions/components/TitleJournal";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ActionsState } from "redux/actions/actions_types";
import { TyresState } from "redux/tyres/tyres_types";

const TyreUtilizationDetail = () => {
  const { tyre_id } = useParams<{ tyre_id?: string }>();
  const today = moment(new Date()).format("YYYY-MM-DD");
  const { loading_tyres, tyre }: any = useTypedSelector((state) => state.tyres);
  const { actions, loading }: ActionsState = useTypedSelector((state) => state.actions);
  const { get_constructor_all } = useTypedSelector<any>((state) => state.design);
  const { getTyre, getActionsPeriod, getConstructorAll, getAxelActionsAll } = useActions();

  const titles = [
    "ДАТА",
    "СОБЫТИЕ",
    "ТРАНСПОРТ",
    "ШИНА",
    "МЕСТОПОЛОЖЕНИЕ",
    "ПРОБЕГ",
    "ГЛУБИНА ПРОТЕКТОРА",
  ];
  const init = () => {
    if (!tyre_id) return;
    getConstructorAll();
    getTyre(+tyre_id);
    getActionsPeriod(today, today);
    getAxelActionsAll();
  };
  useEffect(() => {
    init();
  }, [tyre_id]);

  const num_id = Number(tyre_id);
  const tyre_id_actions = actions?.actions?.filter((el: any) => el.tyre_id === num_id);
  const desingExisting = (id: number) => {
    const item = get_constructor_all?.data.find((el: any) => el.vehicle_id === id);
    return {
      modelId: item?.model_id,
      trailerId: item?.trailer_id,
    };
  };
  const renderActionItem = (action: any) => (
    <ActionItemLine
      key={action.id}
      action_id={action.id}
      date={action.updated_at}
      type={action.type}
      car_title={
        action?.tyre?.history_tyres[0]?.vehicle_name ||
        action?.tyre?.vehicle?.title ||
        "No title available"
      }
      run={action.run || "N/A"}
      tread={action.tread || "N/A"}
      position_tyre={action?.tyre?.history_tyres[0]?.position_tyre || action?.tyre?.position_tyre}
      model={
        action?.tyre?.history_tyres[0]?.model_id ||
        desingExisting(action?.tyre?.vehicle?.id).modelId
      }
      trailer={
        action?.tyre?.history_tyres[0]?.trailer_id ||
        desingExisting(action?.tyre?.vehicle?.id).trailerId ||
        null
      }
    />
  );
  return (
    <>
      {loading_tyres ? (
        <Loader />
      ) : (
        <>
          <div className="report_head">
            <TyreTitle title="Шина" subtitle="информация" />
            {/* {vehickeModel} */}
          </div>
          {tyre && <TyreHead {...tyre} />}
          {actions?.actions.length != 0 ? (
            <div className="report_head">
              <TyreTitle title="События" subtitle="за период" />
              <TyreDate from={actions?.from || today} to={actions?.to} />
              <TitleJournal />
              {tyre_id_actions?.map(renderActionItem)}
            </div>
          ) : (
            <div className="report_head">
              <TyreTitle title="События" subtitle="за период" />
              <TyreHeader titles={titles} classes="tyre_table_box-8" />
              <div className="border-text">События по шине не найдены.</div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TyreUtilizationDetail;
